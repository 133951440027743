const EcxlamationIcon = () => {

    return (
        <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='ecxlamation'>
            <g clipPath="url(#clip0_128_578)">
                <path d="M25.1628 33.4884C23.1486 31.4844 21.2816 29.2874 19.5814 26.9204C17.0299 23.3657 14 18.0718 14 13.0272C13.9989 10.4509 14.653 7.93214 15.8796 5.78964C17.1062 3.64713 18.8502 1.97723 20.8907 0.991263C22.9313 0.00529519 25.1767 -0.252416 27.3429 0.250745C29.509 0.753906 31.4985 1.99532 33.0596 3.81788C34.099 5.02482 34.9228 6.46022 35.4834 8.04101C36.0439 9.62179 36.3302 11.3166 36.3255 13.0272C36.3255 18.0718 33.2956 23.3657 30.7441 26.9204C29.0439 29.2873 27.1769 31.4843 25.1628 33.4884Z" fill="#B81D13" />
                <circle cx="25.1628" cy="41.3023" r="6.69767" fill="#B81D13" />
                <path className="ecxlamation__line" fillRule="evenodd" clipRule="evenodd" d="M27 1.13515C25.116 0.814954 23.1847 1.06584 21.4179 1.86512C19.5158 2.72584 17.8901 4.18306 16.7468 6.05278C15.666 7.82021 15.062 9.8809 15 12C17.9094 7.12988 22.0879 3.30661 27 1.13515Z" fill="white" fillOpacity="0.4" />
                <ellipse className="ecxlamation__line" cx="27.2334" cy="43.3865" rx="2.99835" ry="3.31338" transform="rotate(44.8257 27.2334 43.3865)" fill="white" fillOpacity="0.4" />
            </g>
            <defs>
                <clipPath id="clip0_128_578">
                    <rect width="50" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EcxlamationIcon;