import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../common/Button/Button";
import { degreeColor, degreeIcon, degreeText } from "./constants";
import { images } from "../../helpers/importImg";
import "./repetition-topics.scss";

function RepetitionTopics({ themes, themeTopic }) {
  const history = useHistory();

  const setThemePage = (themeId) => {
    history.push("learn");
    localStorage.setItem("currentThemeId", Number(themeId));
  };

  return (
    <div className="repetition-topics">
      {themes.length > 0 ? (
        themes.map((theme) => (
          <div className="repetition-topic" key={theme.theme_id}>
            <div>
              <div className="repetition-topic__title">
                Тема {theme.theme_id}
              </div>
              <div className="repetition-topic__subtitle">{theme.title}</div>
            </div>
            <div
              className={`repetition-topic__degree ${degreeColor(themeTopic)}`}
            >
              <span>{degreeText(themeTopic)}</span>
              <img
                src={images[degreeIcon(themeTopic)]}
                className="repetition-topic__icon"
                alt="Слабка"
                title="Слабка"
              />
            </div>
            <Button
              btnText="Повторити"
              otherClass="repetition-topic__btn"
              bg="yellow"
              rounded="md"
              onClick={() => setThemePage(theme.theme_id)}
            />
          </div>
        ))
      ) : (
        <div className="repetition-plug">Тем для повторення немає!</div>
      )}
    </div>
  );
}

export default RepetitionTopics;
