import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Title from "../../common/Title/Title";
import AuthForm from "./AuthForm";
import "./auth.scss";

const Auth = ({
  onLogin,
  token,
  errors,
  setLogin,
  setErrors,
  systemError,
  setSystemErrors,
}) => {
  const history = useHistory();

  useEffect(() => {
    setLogin(sessionStorage.getItem("token"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="main">
      <Header />

      <main className="main__container">
        <div className="auth container">
          <div className="auth__container">
            <Title
              title="Онлайн тренажер для вивчення і перевірки знань з Правил Дорожнього руху України"
              titlesize="sm"
              titleClasses="auth__title"
              titleTag="h1"
              subtitle="Авторизація в системі"
              subtitleColor="green"
              position="center"
            />
            <AuthForm
              onLogin={onLogin}
              errors={errors}
              setErrors={setErrors}
              systemError={systemError}
              setSystemErrors={setSystemErrors}
            />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Auth;
