import Blocks from "./Blocks";

function AboutUs({ usersAmounts }) {

  const content = [
    {
        id: 1,
        title: 'Cервіс доступний в будь-який час і будь-де',
        description: '24/7'
    },
    {
        id: 2,
        title: 'Стільки років наша школа існує та вдосконалюється',
        description: '5'
    },
    {
        id: 3,
        title: 'Кількість учнів, які успішно завершили навчання',
        description: `${usersAmounts}`
    },
    {
        id: 4,
        title: 'Учнів успішно здали теоретичний іспит в сервісному центрі МВС з ПЕРШОГО РАЗУ',
        description: '87%'
    }
]

  return (
    <div className="about-us">
        <div className="container">
            <h2 className="about-us__title title title-md">Про нас</h2>

            <dl className="about-us__blocks">
				{content.map(block => <Blocks key={block.id} block={block} usersAmounts={usersAmounts} />)}
            </dl>
        </div>
    </div>
  )
}

export default AboutUs;