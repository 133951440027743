import React from "react";
import { connect } from "react-redux";
import Auth from "../components/Auth/Auth";
import Actions from "../redux/actions/index";

export const AuthHOC = (props) => {
  return <Auth {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  errors: state.auth.errors,
  systemError: state.auth.systemError,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (data) => dispatch(Actions.auth.loginRequest(data)),
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
  setErrors: (data) => dispatch(Actions.auth.getLoginErrors(data)),
  setSystemErrors: (data) => dispatch(Actions.auth.getLoginSystemErrors(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthHOC);
