import Progress from "../../../../hoc/progress";
import "./progress-section.scss";

const ProgressSection = () => {
  return (
    <div className="progress-section">
      <div className="container">
        <h2 className="title title-md">Ваш прогрес</h2>
        <div className="progress-section__content">
          <Progress />
        </div>
      </div>
    </div>
  );
};

export default ProgressSection;
