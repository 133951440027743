import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";
import ReactInputMask from "react-input-mask";
import makeAnimated from "react-select/animated";
import FieldPhone from "../../common/Fields/FieldPhone/FieldPhone";
import Error from "../../common/Error/Error";
import Field from "../../common/Fields/Field/Field";
import FieldPassword from "../../common/Fields/FieldPassword/FieldPassword";
import Checkbox from "../../common/Checkbox/Checkbox";
import Button from "../../common/Button/Button";

const RegisterForm = ({
  price,
  errors,
  errorSystem,
  categories,
  onRegister,
  setErrors,
  setErrorsSystem,
  setBuyPremium,
}) => {
  const history = useHistory();

  const [user, setUser] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    categories: ["2"],
    group_code: "",
    buy_premium: false,
    accept: false,
  });
  const [qrCodeValue, setQrCodeValue] = useState(null);

  const codeParam = JSON.parse(sessionStorage.getItem("codeParam"));

  useEffect(() => {
    if (codeParam) {
      setUser({ ...user, group_code: codeParam });
      setQrCodeValue(codeParam);
    }
    // eslint-disable-next-line
  }, [codeParam]);

  useEffect(() => {
    if (user.buy_premium) {
      setBuyPremium(true);
    } else {
      setBuyPremium(false);
    }
  }, [user]);

  const handleRegister = (e) => {
    e.preventDefault();
    const data = {
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      password: user.password,
      password_confirmation: user.password_confirmation,
      categories: user.categories,
      group_code: user.group_code,
      buy_premium: user.buy_premium,
      accept: 1,
    };
    setErrors({});
    setErrorsSystem("");
    onRegister(data);
  };

  function isCategoryB(category) {
    return category.label === "B";
  }

  const setBack = () => {
    history.goBack();
    setErrors({});
    setErrorsSystem("");
  };

  const animatedComponents = makeAnimated();

  return (
    <form className="auth__form" onSubmit={handleRegister}>
      <div>
        <FieldPhone
          labelText="Номер телефону"
          id="phone"
          name="phone"
          type="tel"
          forLabel="phone"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              phone: e.target.value.trim(),
            }))
          }
        />
        {errors?.phone && <Error errorText={errors.phone} />}
      </div>

      <div>
        <Field
          labelText="Ім’я"
          id="first_name"
          name="first_name"
          type="text"
          forLabel="first_name"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              first_name: e.target.value.trim(),
            }))
          }
        />
        {errors?.first_name && <Error errorText={errors.first_name} />}
      </div>

      <div>
        <Field
          labelText="Прізвище"
          id="last_name"
          name="last_name"
          type="text"
          forLabel="last_name"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              last_name: e.target.value.trim(),
            }))
          }
        />
        {errors?.last_name && <Error errorText={errors.last_name} />}
      </div>

      <div>
        <FieldPassword
          labelText="Пароль"
          id="password"
          name="password"
          type="password"
          forLabel="password"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              password: e.target.value.trim(),
            }))
          }
        />
        {errors?.password && <Error errorText={errors.password} />}
      </div>

      <div>
        <FieldPassword
          labelText="Повторіть пароль"
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          forLabel="password_confirmation"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              password_confirmation: e.target.value.trim(),
            }))
          }
        />
        {errors?.password_confirmation && (
          <Error errorText={errors.password_confirmation} />
        )}
      </div>

      {categories && (
        <div>
          <div className="field">
            <label className="field__title" htmlFor="categories">
              Категорія
            </label>

            <Select
              className="field__select select"
              name="categories"
              id="categories"
              onChange={(val) =>
                setUser((prev) => ({
                  ...prev,
                  categories: val.map((v) => v.id),
                }))
              }
              options={categories.data}
              isMulti
              defaultValue={categories.data.find(isCategoryB)}
              classNamePrefix="select"
              components={animatedComponents}
              placeholder="Оберіть категорію прав:"
            />
          </div>
          {errors?.categories && <Error errorText={errors.categories} />}
        </div>
      )}

      <div>
        <ReactInputMask
          mask="999 99 999"
          maskChar="_"
          value={user.group_code}
          onChange={(e) =>
            setUser((prev) => ({
              ...prev,
              group_code: e.target.value.trim(),
            }))
          }
        >
          {(inputProps) => {
            return (
              <Field
                labelText="Код групи (якщо він Вам відомий)"
                id="group_code"
                name="group_code"
                type="text"
                forLabel="group_code"
                inputSize="sm"
                inputColor="white"
                isDisabled={qrCodeValue ? true : false}
                {...inputProps}
              />
            );
          }}
        </ReactInputMask>
        {errors?.group_code && <Error errorText={errors.group_code} />}
      </div>

      <div className="auth__info">
        <div>
          <Checkbox
            name="buy_premium"
            labelText={`Придбати Преміум (${price}грн на 3 місяці)`}
            checked={user.buy_premium}
            onChange={(e) =>
              setUser((prev) => ({
                ...prev,
                buy_premium: e.target.checked,
              }))
            }
          />
          {errors?.buy_premium && <Error errorText={errors.buy_premium} />}
        </div>

        <p className="auth__text">
          Натиснувши кнопку «Придбати Преміум» Ви будете перенаправлені на
          платіжну систему, після успішної оплати підписки Ви зможете
          користуватись всіма перевагами тарифу «Преміум»
        </p>
        <p className="auth__text auth__text--bold">
          Вартість тарифу «Преміум» складає: <span>{price} грн</span>
        </p>
        <p className="auth__text auth__text--bold">
          Термін дії тарифу <span>3 місяці</span> з моменту реєстрації.
        </p>
      </div>

      <div className="auth__check">
        <Checkbox
          name="accept"
          labelText="Я погоджуюсь з"
          linkText="умовами угоди користувача"
          link="/agreement"
          required
          checked={user.accept}
          onChange={(e) =>
            setUser((prev) => ({
              ...prev,
              accept: e.target.checked,
            }))
          }
        />
        {errors?.accept && <Error errorText={errors.accept} />}
      </div>

      {errorSystem && <Error errorText={errorSystem} />}
      {Object.keys(errors).length > 0 && (
        <Error errorText="Заповніть правильно дані!" size="lg" />
      )}

      <div className="auth__btns">
        <Button btnText="Зареєструватись" bg="yellow" type="submit" />
        <Button btnText="Назад" bg="black" onClick={setBack} />
      </div>
    </form>
  );
};

export default RegisterForm;
