import { useEffect } from "react";
import Plug from "../../../common/Plugs/Plug";
import PlugPage from "../../PlugPage/Plug";
import Header from "../../../hoc/header";

const ProfileEdit = ({ token, setLogin }) => {
  useEffect(() => {
    if (!token) {
      const tokenData = sessionStorage.getItem("token");
      setLogin(tokenData);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main">
      <Header />

      <main className="main__container">
        <PlugPage buttonText="Повернутись назад" goBack={true} />
      </main>
    </div>
  );
};

export default ProfileEdit;
