import Item from "./Item";

import { images } from "../../../../helpers/importImg";

import { content } from "./data";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function Advantages({ token, user }) {
  const setBtn = () => {
    if (token) {
      if (user.premium_check) {
        return <></>;
      } else {
        return (
          <NavLink
            to="/premium"
            className="advantages__btn btn btn--yellow btn--md"
          >
            {content.btnText}
          </NavLink>
        );
      }
    } else {
      return (
        <NavLink
          to="/register"
          className="advantages__btn btn btn--yellow btn--md"
        >
          {content.btnText}
        </NavLink>
      );
    }
  };

  return (
    <>
      <div className="advantages">
        <div className="container">
          <h2 className="advantages__title title title-md">{content.title}</h2>

          <div className="advantages__container">
            <div className="advantages__photo">
              <img
                src={images["benefits-photo-1.png"]}
                alt="Benefits title"
                width="1"
                height="1"
                decoding="async"
              />
            </div>

            <ul className="advantages-content">
              {content.items.map((item) => (
                <Item key={item.id} item={item} />
              ))}
            </ul>
          </div>

          {!user.premium_check ? setBtn() : ""}
        </div>
      </div>
    </>
  );
}

export default Advantages;
