import StarExam from "../../hoc/starExam";
import RoadBlock from '../../hoc/roadBlock';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { pathColor } from "./helpers";
import { images } from "../../helpers/importImg";

import 'react-circular-progressbar/dist/styles.css';

export default function MapMobile({
    blocks,
    selectedTheme,
    blocksDone,
    setAllowOpenModal
}) {

    // ---------------- SET COLOR FOR PROGRESSBAR CAR FOR SELECTED THEME ------------------------
    const styleProgressbarCar = {
        pathColor: pathColor(selectedTheme),
        trailColor: '#46494F'
    }

    return (
        <div className="map-mobile">
            <StarExam
                selectedTheme={selectedTheme}
                blocksDone={blocksDone}
                setAllowOpenModal={setAllowOpenModal}
                starClass='map-mobile__star'
                starCursorClass='map-mobile__star--cursor'
            />

            <div className="map-mobile__progressbar progressbar-car">
                <CircularProgressbar
                    value={selectedTheme?.progress_car?.progress}
                    className="progressbar-car__circle"
                    styles={buildStyles(styleProgressbarCar)}
                />
                <div className="progressbar-car__block">
                    <img src={images['car.svg']} className="progressbar-car__img" />
                    <p className="progressbar-car__num">{selectedTheme?.progress_car?.progress}%</p>
                </div>
            </div>

            <dir className="map-mobile__map">
                <img src={images['road-mobile-1.svg']} className="map-mobile__img" alt="Road" width="1" height="1" />
                {blocks?.length > 4 && <img src={images['road-mobile-2.svg']} className="map-mobile__img" alt="Road" width="1" height="1" />}
                {blocks?.length > 8 && <img src={images['road-mobile-2.svg']} className="map-mobile__img map-mobile__img--rotate" alt="Road" width="1" height="1" />}
                {blocks?.length > 12 && <img src={images['road-mobile-1.svg']} className="map-mobile__img map-mobile__img--rotate" alt="Road" width="1" height="1" />}
                {blocks?.length > 16 && <img src={images['road-mobile-1.svg']} className="map-mobile__img" alt="Road" width="1" height="1" />}
            </dir>

            {blocks?.map((block, idx) => <div key={idx}>
                <RoadBlock
                    block={block}
                    blocks={blocks}
                    idx={idx}
                    selectedTheme={selectedTheme}
                    setAllowOpenModal={setAllowOpenModal}
                />
            </div>)}
        </div>
    )
}
