import React from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar/Sidebar";
import Actions from "../redux/actions/index";

export const SiderHOC = (props) => {
  return <Sidebar {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  subscribe: state.general.subscribe,
  user: state.auth.user,
  price: state.general.price,
});

const mapDispatchToProps = (dispatch) => ({
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  setSubscribe: (data) => dispatch(Actions.general.getSubscribe(data)),
  removeSubscribe: (data) => dispatch(Actions.general.getSubscribeRemove(data)),
  onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
  setPrice: () => dispatch(Actions.general.getConfig()),
  // ------------------- RESET RESULTS -------------------------
  onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
  onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),
  onSetAllQuestions: (data) =>
    dispatch(Actions.test.getAllQuestionsSuccess(data)),
  onSetExamQuestions: (data) =>
    dispatch(Actions.test.getExamQuestionsSuccess(data)),

  setRegister: (data) => dispatch(Actions.register.registerSuccess(data)),
  setRecover: (data) => dispatch(Actions.recover.recoverSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiderHOC);
