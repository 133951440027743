import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Title from "../../common/Title/Title";
import RegisterForm from "./RegisterForm";
import "./auth.scss";

const Register = ({
  token,
  link,
  errors,
  errorSystem,
  price,
  categories,
  onRegister,
  setCategories,
  setConfig,
  setErrors,
  setErrorsSystem,
}) => {
  const history = useHistory();
  const [buyPremium, setBuyPremium] = useState(false);

  useEffect(() => {
    if (token) {
      if (buyPremium) {
        if (link) {
          window.location.href = link;
          sessionStorage.removeItem("link");
          setErrors({});
          setErrorsSystem("");
        }
      } else {
        history.push("/");
      }
    }
  }, [token, buyPremium, link]);

  useEffect(() => {
    setConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main">
      <Header />

      <main className="main__container">
        <div className="auth container">
          <div className="auth__container">
            <Title
              title="Онлайн тренажер для вивчення і перевірки знань з Правил Дорожнього руху України"
              titlesize="sm"
              titleClasses="auth__title"
              titleTag="h1"
              subtitle="Реєстрація в системі"
              subtitleColor="green"
              position="center"
            />
            <RegisterForm
              price={price}
              errors={errors}
              errorSystem={errorSystem}
              categories={categories}
              onRegister={onRegister}
              setErrors={setErrors}
              setErrorsSystem={setErrorsSystem}
              setBuyPremium={setBuyPremium}
            />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Register;
