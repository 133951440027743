import React from "react";
import Header from "../../hoc/header";
import PlugPage from "../PlugPage/Plug";
import Plug from "../../common/Plugs/Plug";

const Theory = ({ token }) => {
  return (
    <div className="main">
      <Header />

      <main className="main__container">
        {token ? (
          <PlugPage />
        ) : (
          <div className="cabinet-plug">
            <Plug plugText="Увійдіть, щоб потрапити в кабінет" />
          </div>
        )}
      </main>
    </div>
  );
};

export default Theory;
