import types from "../types/index";

const initialState = {
  themes: [],
  themesError: false,
  themesCount: {},
  themesCountError: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.repeat.REPEAT_THEMES_SUCCESS:
      return {
        ...state,
        themes: payload,
      };
    case types.repeat.REPEAT_THEMES_ERROR:
      return {
        ...state,
        themesError: payload,
      };
    case types.repeat.REPEAT_THEMES_COUNT_SUCCESS:
      return {
        ...state,
        themesCount: payload,
      };
    case types.repeat.REPEAT_THEMES_COUNT_ERROR:
      return {
        ...state,
        themesCountError: payload,
      };
    default:
      return state;
  }
};

export default reducer;
