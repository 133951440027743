import { useEffect, useState } from "react";
import Head from "../../common/Head/Head";
import Plug from "../../common/Plugs/Plug";
import Header from "../../hoc/header";
import RepetitionStatistics from "./RepetitionStatistics";
import RepetitionTopics from "./RepetitionTopics";
import Alert from "../../common/Alert/Alert";
import "./repetition.scss";

const Repetition = ({
  token,
  themes,
  themesError,
  setRepeatThemes,
  themesCount,
  themesCountError,
  setRepeatThemesCount,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [themesType, setThemesType] = useState("low");
  const [themeTopic, setThemeTopic] = useState("");

  const alertText = "Виникла помилка!";

  useEffect(() => {
    if (themes.length === 0 && token) {
      setRepeatThemes({ type: themesType, token: token });
    }
  }, [token]);

  useEffect(() => {
    if (token && themesType && themes.length > 0) {
      setRepeatThemes({ type: themesType, token: token });
    }
  }, [themesType, token]);

  useEffect(() => {
    if (themes.length > 0 && themesType) {
      setThemeTopic(themesType);
    }
  }, [themes]);

  const toggleThemes = (index, type) => {
    setActiveIndex(index);
    setThemesType(type);
  };

  useEffect(() => {
    if (token) {
      setRepeatThemesCount(token);
    }
  }, [token]);

  return (
    <div className="main">
      {(themesError || themesCountError) && <Alert alertText={alertText} />}
      <Header />

      <main className="main__container">
        {token ? (
          <div className="repetition container container--sm">
            <Head title="Повторення" subtitle="Робота над помилками" />
            <RepetitionStatistics
              themesCount={themesCount}
              toggleThemes={toggleThemes}
              activeIndex={activeIndex}
            />
            <RepetitionTopics themes={themes} themeTopic={themeTopic} />
          </div>
        ) : (
          <div className="cabinet-plug">
            <Plug plugText="Увійдіть, щоб потрапити в кабінет" />
          </div>
        )}
      </main>
    </div>
  );
};

export default Repetition;
