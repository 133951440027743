import StarIcon from "../../images/StarIcon";
import PlaceIcon from "../../images/PlaceIcon";
import EcxlamationIcon from "../../images/EcxlamationIcon";

import { images } from "../../helpers/importImg";

const IconCard = ({ theme }) => {
  if (theme) {
    if (theme.blocked && window.innerWidth > 767) {
      return <img src={images["lock-gray.svg"]} alt="Locked card" />;
    }

    if (theme.blocked && window.innerWidth < 768) {
      return (
        <img
          src={images["lock-black.svg"]}
          alt="Locked card"
          className="card-mobile__icon"
        />
      );
    }

    if (theme.disabled) {
      return <PlaceIcon />;
    }

    if (!theme.disabled && theme.error > 0) {
      return <EcxlamationIcon />;
    }

    if (!theme.disabled && theme.error === 0 && theme.progress === 100) {
      return <StarIcon />;
    }

    if (!theme.disabled || (!theme.disabled && theme.progress < 100)) {
      return <PlaceIcon />;
    }
  }

  return <></>;
};

export default IconCard;
