import { images } from "../../../../helpers/importImg";

function PreferenceBlock({block}) {

    return (
        <div className="preference-block">
            <img src={images[block.icon]} className='preference-block__img' alt={block.title} />
            
            <h3 className="preference-block__title">{block.title}</h3>

            <p className="preference-block__text">{block.text}</p>
        </div>
    )
}

export default PreferenceBlock;