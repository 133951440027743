export default {
  GET_THEMES_REQUEST: "GET_THEMES_REQUEST",
  GET_THEMES_SUCCESS: "GET_THEMES_SUCCESS",

  CURRENT_THEME: "CURRENT_THEME",

  GET_PREMIUM_SUBSCRIBE: "GET_PREMIUM_SUBSCRIBE",
  GET_PREMIUM_SUBSCRIBE_SUCCES: "GET_PREMIUM_SUBSCRIBE_SUCCES",
  GET_PREMIUM_SUBSCRIBE_REMOVE: "GET_PREMIUM_SUBSCRIBE_REMOVE",

  GET_CONFIG: "GET_CONFIG",
  GET_CONFIG_SUCCESS: "GET_CONFIG_SUCCESS",
  GET_PREMIUM_PRICE_SUCCESS: "GET_PREMIUM_PRICE_SUCCESS",
  GET_USERS_AMOUNT_SUCCESS: "GET_USERS_AMOUNT_SUCCESS",

  GET_READ_MODAL_INFO_EXAM: "GET_READ_MODAL_INFO_EXAM",
  SET_READ_MODAL_INFO_EXAM: "SET_READ_MODAL_INFO_EXAM",
};
