import { axiosInstance } from "./index";

export const apiFunctions = {
  getFunc: (data, url, token) => {
    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...data,
      },
    };
    return axiosInstance
      .get(url, config)
      .then((response) => response)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },

  postFunc: (data, url, token) => {
    const jsonData = JSON.stringify(data);
    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return axiosInstance
      .post(url, jsonData, config)
      .then((response) => response)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },
};
