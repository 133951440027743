import { apiFunctions } from "./apiFunctions";

export const test = {
  getQuestions: (data) => apiFunctions.getFunc({ theme_id: data.id }, "/get-questions-themes-crypt", data.token),
  getAllQuestions: (data) => apiFunctions.getFunc({ theme_id: data.id }, "/get-all-questions-themes-crypt", data.token),
  getExamQuestions: (data) => apiFunctions.getFunc(null, "/get-questions-exam-crypt", data),
  getRetestQuestions: (data) => apiFunctions.getFunc(null, `result/incorrect/${data.id}/${data.idx}`, data.token),
  getQuestionsFromSession: (data) => apiFunctions.getFunc(null, "/get-question-from-session-crypt", data),
  getBlockQuestions: (data) => apiFunctions.getFunc(null, `/theme/block/${data.id}/${data.idx}`, data.token),
  getCrypt: (data) => apiFunctions.getFunc(null, "/exam-crypt", data),
};
