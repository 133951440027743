import { NavLink } from "react-router-dom";

import { images } from "../../../../helpers/importImg";

function CardTariff({ card, token, user, price }) {
  const setLite = () => {
    if (token) {
      if (user.premium_check) {
        return <></>;
      } else {
        return (
          <NavLink
            to="/started_learn"
            className="card-tariff__btn btn btn--yellow"
          >
            {card.btnText}
          </NavLink>
        );
      }
    } else {
      return (
        <NavLink to="/register" className="card-tariff__btn btn btn--yellow">
          Зареєструватись
        </NavLink>
      );
    }
  };

  const setPremium = () => {
    if (token) {
      if (user.premium_check) {
        return <></>;
      } else {
        return (
          <NavLink to="/premium" className="card-tariff__btn btn btn--yellow">
            {card.btnText}
          </NavLink>
        );
      }
    } else {
      return (
        <NavLink to="/register" className="card-tariff__btn btn btn--yellow">
          {card.btnText}
        </NavLink>
      );
    }
  };

  return (
    <div className="card-tariff">
      <div className="card-tariff__img">
        <img
          src={images[card.iconName]}
          alt={`${card.title}`}
          width="1"
          height="1"
          decoding="async"
        />
      </div>

      <div className="card-tariff-title">
        <h3 className="card-tariff-title__text">{card.title}</h3>
      </div>

      <div className="card-tariff__price">
        {card.id === 1 ? `${price}грн` : card.price}
      </div>
      <div className="card-tariff__text">
        {card.text.map((item) => (
          <p>{item}</p>
        ))}
      </div>
      {card.id === 1 && setPremium()}
      {card.id === 2 && setLite()}
    </div>
  );
}

export default CardTariff;
