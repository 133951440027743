import { useEffect, useState } from "react";

import Button from "../../../../common/Button/Button";
import ModalInfoPremiun from "../../../../common/Modal/ModalInfoPremiun";

import { date, days, hours, minutes, seconds } from "../../../../helpers/times";
import { images } from "../../../../helpers/importImg";
import Alert from "../../../../common/Alert/Alert";

function TryIt({
  token,
  onGetFreePremium,
  premiumContent,
  freePremium,
  onGetFreePremiumSubscribe,
  freePremiumMessage,
  onSetFreePremiumMessage,
  onSetFreePremiumSubscribe,
  onGetUser,
}) {
  const [timerText, setTimerText] = useState(null);
  const [modalInfoPremium, setModalInfoPremium] = useState(false);

  const timeData = premiumContent.timer;

  useEffect(() => {
    onGetFreePremium();
  }, []);

  useEffect(() => {
    if (freePremium && token) {
      onGetUser(token);
    }
  }, [freePremium]);

  useEffect(() => {
    const img = document.querySelector(".tryit__img");

    let time;

    const timer = setInterval(() => {
      if (time < 0) {
        if (img) {
          img.classList.remove("rotateTime");
        }
      } else {
        const deadline = Date.parse(new Date(timeData));
        let time = deadline - Date.parse(new Date()) + 1;
        setTimerText(date(days(time), minutes(time), seconds(time)));
        if (img) {
          img.classList.add("rotateTime");
        }
      }

      --time;
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [timeData]);

  const onModal = () => {
    if (token) {
      onGetFreePremiumSubscribe(token);
    } else {
      setModalInfoPremium(true);
      document.body.classList.add("open");
    }
  };

  const setAlert = () => {
    if (freePremium) {
      return (
        <Alert alertText={freePremium} resetText={onSetFreePremiumSubscribe} />
      );
    }

    if (freePremiumMessage) {
      return (
        <Alert
          alertText={freePremiumMessage}
          resetText={onSetFreePremiumMessage}
        />
      );
    }
  };

  if (!timeData) {
    return <></>;
  }

  return (
    <div className="tryit">
      {setAlert()}

      {modalInfoPremium && (
        <ModalInfoPremiun
          setModalInfoPremium={setModalInfoPremium}
          content={premiumContent}
        />
      )}

      <div className="container tryit__container">
        <h2 className="title">{premiumContent.title}</h2>

        <p className="tryit__desc">{premiumContent.description}</p>

        <Button
          bg="yellow"
          size="md"
          otherClass="tryit__btn"
          btnText="Спробувати преміум"
          onClick={onModal}
        />

        <img
          src={images["time.svg"]}
          className="tryit__img"
          alt="time"
          width="1"
          height="1"
          decoding="async"
        />

        <div className="tryit__time">
          <div>Пропозиція спливає через:</div>
          <div className="tryit__time-bold">{timerText}</div>
        </div>
      </div>
    </div>
  );
}

export default TryIt;
