import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import Footer from "../../hoc/footer";
import Header from "../../hoc/header";

import Preference from "./Sections/Preference/Preference";
import AboutUs from "./Sections/AboutAs/AboutUs";
import Advantages from "./Sections/Advantages/Advantages";
import Banner from "./Sections/Banner/Banner";
import Tariffs from "./Sections/Tariffs/Tariffs";
import TryIt from "./Sections/TryIt/TryIt";
import Feedback from "./Sections/Feedback/Feedback";
import ProgressSection from "./Sections/ProgressSection/ProgressSection";

function Main({
  token,
  user,
  onGetFreePremium,
  premiumContent,
  errorsPremium,
  feedback,
  errorsFeedback,
  onGetFeedback,
  onSetFeedbackSucces,
  onSetFeedbackErrors,
  onGetFreePremiumSubscribe,
  freePremium,
  onSetFreePremiumSubscribe,
  onSetFreePremiumMessage,
  freePremiumMessage,
  onGetUser,
  price,
  setConfig,
  usersAmounts,
}) {
  const location = useLocation();

  useEffect(() => {
    setConfig();
  }, []);

  const searchParams = new URLSearchParams(location.search);
  const codeParam = searchParams.get("code");

  if (codeParam) {
    let newCodeParam;
    if (codeParam.includes("-")) {
      newCodeParam = codeParam.replace(/-/g, " ");
    } else {
      const percentEncodedString = JSON.stringify(codeParam)
        .replace(/\\u00/g, " ")
        .replace(/"/g, "");
      newCodeParam = decodeURIComponent(percentEncodedString);
    }

    sessionStorage.setItem("codeParam", JSON.stringify(newCodeParam));
  }

  return (
    <div className="main">
      <Header />

      <main>
        <Banner token={token} />

        {token && user.premium_check ? (
          <ProgressSection />
        ) : (
          <>
            <AboutUs usersAmounts={usersAmounts} />
            <Tariffs token={token} user={user} price={price} />
          </>
        )}
        <Advantages token={token} user={user} />
        <Preference />
        <TryIt
          token={token}
          onGetFreePremium={onGetFreePremium}
          premiumContent={premiumContent}
          errorsPremium={errorsPremium}
          onGetFreePremiumSubscribe={onGetFreePremiumSubscribe}
          freePremium={freePremium}
          onSetFreePremiumSubscribe={onSetFreePremiumSubscribe}
          freePremiumMessage={freePremiumMessage}
          onSetFreePremiumMessage={onSetFreePremiumMessage}
          onGetUser={onGetUser}
        />
        <Feedback
          feedback={feedback}
          errorsFeedback={errorsFeedback}
          onGetFeedback={onGetFeedback}
          onSetFeedbackSucces={onSetFeedbackSucces}
          onSetFeedbackErrors={onSetFeedbackErrors}
        />
      </main>

      <div className="main__footer">
        <Footer />
      </div>
    </div>
  );
}

export default Main;
