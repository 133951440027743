import { useEffect, useRef, useState } from "react";
import { images } from "../../helpers/importImg";
import { DropdownList } from "../Dropdown/DropdownList";
import { profileLinks } from "./constants";

export const HeaderProfileDropdown = ({ user, logoutUser }) => {
  const dropdownRef = useRef(null);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpened(false);
    }
  };

  const icon = user.premium_check ? "person-premium.svg" : "person.svg";

  return (
    <div
      className={`dropdown dropdown--header ${dropdownOpened ? "open" : ""}`}
      ref={dropdownRef}
    >
      <button type="button" onClick={() => setDropdownOpened((o) => !o)}>
        <img
          src={images[icon]}
          className="header-learn__profile"
          alt="User profile"
          width="1"
          height="1"
        />
      </button>

      <DropdownList user={user} items={profileLinks} logoutUser={logoutUser} />
    </div>
  );
};
