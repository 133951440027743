import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetTerm() {
  const response = yield API.agreement.getTerm();

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Угоди не знайдено"));
    } else {
      yield sessionStorage.setItem("term", JSON.stringify(response.data));
      yield put(Actions.agreement.getTermPageDataSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Угоди не знайдено"));
  }
}