import React from "react";
import { connect } from "react-redux";
import QrCode from "../components/QrCode/QrCode";
import Actions from "../redux/actions/index";

export const QrCodeHOC = (props) => {
    return <QrCode {...props} />;
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeHOC);