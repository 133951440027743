export const content = {
    title: 'Переваги тарифу Преміум',
    items: [
        {
            id: 1,
            text: 'Найкраща ціна'
        },
        {
            id: 2,
            text: 'Найновіші офіційні тестові питання'
        },
        {
            id: 3,
            text: 'Алгоритм іспиту аналогічний іспиту в сервісному центрі МВС'
        },
        {
            id: 4,
            text: 'Дизайн тренажера наближений до дизайну іспиту в сервісному центрі МВС'
        },
        {
            id: 5,
            text: 'Доступ до додаткових питань за обраними категоріями'
        },
        {
            id: 6,
            text: 'Послідовний доступ до всіх тем та тестових питань'
        },
        {
            id: 7,
            text: 'Теми №33 «Дорожні знаки» та №34 «Дорожня розмітка» доступні для проходження незалежно інших тем'
        }
    ],
    btnText: 'придбати преміум',
}