export const statisticsFunc = (totalProgress) => {
  const statistics = [
    {
      title: "Успішно складені іспити:",
      icon: "trending",
      count: totalProgress.exam_pass,
      color: "",
    },

    {
      title: "Запитань пройдено:",
      icon: "done",
      count: totalProgress.total,
      color: "",
    },

    {
      title: "Вірних відповідей:",
      icon: "mood",
      count: totalProgress.success,
      color: "green",
    },

    {
      title: "Помилок:",
      icon: "mood-bad",
      count: totalProgress.error,
      color: "red",
    },
  ];

  return statistics;
};
