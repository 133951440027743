import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Avatar from "../../../assets/img/avatar.svg";
import Label from "../../common/Label/Label";

const ProfileUser = ({ user }) => {
  return (
    <div className="profile-user">
      <div className="profile-user__img">
        <img
          src={user.avatar ? user.avatar : Avatar}
          alt={`${user.first_name} ${user.last_name}`}
          title={`${user.first_name} ${user.last_name}`}
          width="1"
          height="1"
          decoding="async"
        />
      </div>
      <div className="profile-user__content">
        {user.premium_check ? (
          <Label color="yellow" text="Преміум" uppercase={true} />
        ) : (
          ""
        )}
        <div>
          <div className="profile-user__name">
            {user.first_name} {user.last_name}
          </div>
          {user.group_name ? (
            <div className="profile-user__subtitle">
              Група {user.group_name}
            </div>
          ) : (
            <div className="profile-user__subtitle">Група невідома</div>
          )}
        </div>
        <NavLink
          to="/profile/edit"
          className="profile-user__link link link--green"
        >
          Редагувати профіль
        </NavLink>
      </div>
    </div>
  );
};

export default ProfileUser;
