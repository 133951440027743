import FeedbackForm from "./FeedbackForm";

import { images } from "../../../../helpers/importImg";

function Feedback({feedback, errorsFeedback, onGetFeedback, onSetFeedbackSucces, onSetFeedbackErrors}) {
  return (
    <div className='feedback'>
        <div className="container">

            <div className="feedback-content">

                <div className="feedback-content__left">

                    <div>
                        <h2 className="feedback__title title title-md">Виникли додаткові питання або виникли трудності в роботі системи?</h2>
                        <p className="feedback__text feedback__text--desktop">Залиште свої контакти, і ми відповімо</p>
                    </div>

                    <img src={images['feedback-photo-1.png']} className="feedback__img" alt="Feedback photo" width="1" height="1" decoding="async" loading="lazy" />
                    <p className="feedback__text feedback__text--mobile">Залиште свої контакти, і ми відповімо</p>
                </div>

                <div className="feedback-content__form">
                    <FeedbackForm 
                        feedback={feedback} 
                        errors={errorsFeedback} 
                        onGetFeedback={onGetFeedback} 
                        onSetFeedbackSucces={onSetFeedbackSucces} 
                        onSetFeedbackErrors={onSetFeedbackErrors} 
                    />
                </div>
            </div>

        </div>
    </div>
  )
}

export default Feedback;
