import types from "../types/index";

const progressRequest = (payload) => ({
  type: types.progress.PROGRESS_REQUEST,
  payload,
});

const progressSuccess = (payload) => ({
  type: types.progress.PROGRESS_SUCCESS,
  payload,
});

const progressErrors = (payload) => ({
  type: types.progress.PROGRESS_ERRORS,
  payload,
});

export default {
  progressRequest,
  progressSuccess,
  progressErrors,
};
