import { useEffect, useState } from "react";

import Button from "../../../../common/Button/Button";
import Error from "../../../../common/Error/Error";
import Field from "../../../../common/Fields/Field/Field";
import FieldPhone from "../../../../common/Fields/FieldPhone/FieldPhone";
import Textarea from "../../../../common/Fields/Textarea/Textarea";
import Alert from "../../../../common/Alert/Alert";

function FeedbackForm({
  feedback,
  errors,
  onGetFeedback,
  onSetFeedbackSucces,
  onSetFeedbackErrors,
}) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const resetFields = () => {
    setName("");
    setPhone("");
    setEmail("");
    setMessage("");
  };

  useEffect(() => {
    if (feedback) {
      resetFields();
    }
    // eslint-disable-next-line
  }, [feedback]);

  const sendFeedback = (e) => {
    e.preventDefault();
    const data = { name, phone, email, message };
    onGetFeedback(data);
    onSetFeedbackErrors([]);
  };

  return (
    <>
      <Alert alertText={feedback} resetText={onSetFeedbackSucces} />

      <form className="form" onSubmit={sendFeedback}>
        <>
          <Field
            labelText="Ім’я"
            name="name"
            id="name"
            forLabel="Ім’я"
            value={name}
            onChange={(e) => setName(e.target.value.trim())}
          />
          {errors.name && <Error errorText={errors.name} />}
        </>

        <>
          <FieldPhone
            labelText="Номер телефону"
            name="phone"
            id="phone"
            forLabel="Номер телефону"
            fieldClass="form__field"
            value={phone}
            onChange={(e) => setPhone(e.target.value.trim())}
          />
          {errors.phone && <Error errorText={errors.phone} />}
        </>

        <>
          <Field
            labelText="Email"
            name="email"
            id="email"
            type="email"
            forLabel="Email"
            fieldClass="form__field"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
          {errors.email && <Error errorText={errors.email} />}
        </>

        <>
          <Textarea
            labelText="Повідомлення"
            name="message"
            id="message"
            forLabel="Повідомлення"
            otherClass="form__field"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {errors.message && <Error errorText={errors.message} />}
        </>

        <Button
          btnText="Залишити заявку"
          bg="yellow"
          size="md"
          type="submit"
          otherClass="feedback__btn form__btn"
        />

        <p className="form__text">
          Натиснувши «Залишити заявку», я погоджуюся отримати електронний лист
          та/або дзвінок від PDR.UA
        </p>
      </form>
    </>
  );
}

export default FeedbackForm;
