const StarIcon = () => {
    return (
        <svg width="51" height="48" viewBox="0 0 51 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='star'>
            <path className="star__base" d="M25.7194 0.450262L32.8232 14.9555C32.8808 15.0734 32.9661 15.1754 33.0717 15.2526C33.1774 15.3298 33.3001 15.3798 33.4293 15.3983L49.3141 17.7244C49.4624 17.7459 49.6017 17.8088 49.7163 17.9059C49.8309 18.0031 49.9162 18.1307 49.9625 18.2743C50.0088 18.4178 50.0143 18.5716 49.9783 18.7181C49.9423 18.8646 49.8662 18.998 49.7588 19.1032L38.2626 30.3943C38.1691 30.4855 38.099 30.5984 38.0585 30.723C38.018 30.8476 38.0082 30.9804 38.03 31.1097L40.7437 47.0524C40.7688 47.2011 40.7522 47.3539 40.6958 47.4936C40.6393 47.6333 40.5452 47.7544 40.4242 47.843C40.3031 47.9317 40.1598 47.9845 40.0105 47.9955C39.8612 48.0065 39.7119 47.9752 39.5793 47.9052L25.3732 40.3777C25.258 40.3168 25.1298 40.285 24.9997 40.285C24.8696 40.285 24.7415 40.3168 24.6263 40.3777L10.4187 47.9052C10.2861 47.9752 10.1367 48.0065 9.98744 47.9955C9.83816 47.9845 9.69489 47.9317 9.5738 47.843C9.45271 47.7544 9.35863 47.6333 9.30217 47.4936C9.24571 47.3539 9.22913 47.2011 9.25428 47.0524L11.9694 31.1097C11.9919 30.9808 11.983 30.8484 11.9434 30.7237C11.9038 30.5991 11.8348 30.4861 11.7422 30.3943L0.242918 19.1032C0.135507 18.998 0.0594917 18.8646 0.0234939 18.7181C-0.0125039 18.5716 -0.00704447 18.4178 0.0392532 18.2743C0.0855508 18.1307 0.170834 18.0031 0.285432 17.9059C0.40003 17.8088 0.539358 17.7459 0.687615 17.7244L16.5725 15.3983C16.7016 15.3798 16.8244 15.3298 16.93 15.2526C17.0356 15.1754 17.1209 15.0734 17.1785 14.9555L24.28 0.450262C24.3464 0.315075 24.449 0.20128 24.5762 0.12172C24.7034 0.0421591 24.8501 0 24.9997 0C25.1494 0 25.2961 0.0421591 25.4233 0.12172C25.5505 0.20128 25.653 0.315075 25.7194 0.450262Z" fill="#F3CC30" />
            <path className="star__line" d="M24.9996 3.80081L18.6693 16.1577C18.5054 16.4763 18.2647 16.7481 17.9691 16.9482C17.6736 17.1484 17.3329 17.2703 16.9783 17.3029L3.23364 18.5686L17.7556 19.2557C18.2049 19.2766 18.6491 19.153 19.0241 18.9029C19.3991 18.6527 19.6855 18.2889 19.842 17.864L24.9996 3.80081Z" fill="#F7DB5E" />
            <path d="M9.56622 45.2177L9.2541 47.05C9.22894 47.1987 9.24553 47.3515 9.30199 47.4912C9.35844 47.6309 9.45253 47.7519 9.57361 47.8406C9.6947 47.9293 9.83797 47.9821 9.98726 47.9931C10.1365 48.0041 10.2859 47.9728 10.4185 47.9027L24.6261 40.3753C24.7413 40.3144 24.8694 40.2826 24.9995 40.2826C25.1297 40.2826 25.2578 40.3144 25.373 40.3753L39.5806 47.9027C39.7132 47.9728 39.8626 48.0041 40.0118 47.9931C40.1611 47.9821 40.3044 47.9293 40.4255 47.8406C40.5466 47.7519 40.6407 47.6309 40.6971 47.4912C40.7536 47.3515 40.7702 47.1987 40.745 47.05L38.0299 31.1095C38.0078 30.9804 38.0173 30.8477 38.0576 30.7231C38.0979 30.5984 38.1676 30.4856 38.2609 30.3942L49.7549 19.103C49.8623 18.9979 49.9383 18.8644 49.9743 18.7179C50.0103 18.5714 50.0048 18.4177 49.9585 18.2741C49.9122 18.1306 49.8269 18.003 49.7123 17.9058C49.5977 17.8086 49.4584 17.7457 49.3102 17.7243L43.9912 16.9479C35.3859 32.5074 22.2086 40.7013 9.56622 45.2177Z" fill="#EFB700" />
        </svg>
    )
}

export default StarIcon;

