const PlaceIcon = () => {

    return (
        <svg width="38" height="48" viewBox="0 0 38 48" fill="none" xmlns="http://www.w3.org/2000/svg" className='place'>
            <g clipPath="url(#clip0_128_630)">
                <path d="M18.9583 47.152C15.5375 44.3304 12.3668 41.237 9.47917 37.9042C5.14584 32.8992 2.38034e-06 25.4454 2.38034e-06 18.3425C-0.00187693 14.715 1.10909 11.1685 3.1923 8.15188C5.27551 5.13521 8.23732 2.78396 11.7029 1.39571C15.1685 0.00745569 18.982 -0.355404 22.6609 0.353052C26.3399 1.06151 29.7187 2.80943 32.37 5.37562C34.1352 7.07501 35.5343 9.09607 36.4864 11.3218C37.4384 13.5476 37.9246 15.9339 37.9167 18.3425C37.9167 25.4454 32.7708 32.8992 28.4375 37.9042C25.5499 41.2369 22.3791 44.3303 18.9583 47.152ZM18.9583 10.4854C16.8035 10.4854 14.7368 11.3132 13.2131 12.7867C11.6894 14.2602 10.8333 16.2587 10.8333 18.3425C10.8333 20.4264 11.6894 22.4249 13.2131 23.8984C14.7368 25.3719 16.8035 26.1997 18.9583 26.1997C21.1132 26.1997 23.1798 25.3719 24.7036 23.8984C26.2273 22.4249 27.0833 20.4264 27.0833 18.3425C27.0833 16.2587 26.2273 14.2602 24.7036 12.7867C23.1798 11.3132 21.1132 10.4854 18.9583 10.4854Z" fill="#10AA57" />
                <path className="place__line" fillRule="evenodd" clipRule="evenodd" d="M35.6634 25C30.3098 32.1457 22.7527 37.5453 14 40.1908C15.6866 41.8731 17.4554 43.478 19.3004 45C22.5589 42.3121 25.5793 39.3655 28.3299 36.1908C30.9448 33.1707 33.8702 29.2144 35.6634 25Z" fill="white" fillOpacity="0.4" />
                <path className="place__line" fillRule="evenodd" clipRule="evenodd" d="M21 1.19658C18.017 0.730842 14.959 1.09577 12.1616 2.25836C9.14996 3.51031 6.57597 5.6299 4.76578 8.3495C3.05446 10.9203 2.09823 13.9177 2 17C6.60652 9.91619 13.2225 4.35506 21 1.19658Z" fill="white" fillOpacity="0.4" />
            </g>
            <defs>
                <clipPath id="clip0_128_630">
                    <rect width="38" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PlaceIcon;