import types from "../types/index";

const getThemesRequest = (payload) => ({
  type: types.general.GET_THEMES_REQUEST,
  payload,
});

const getThemesSuccess = (payload) => ({
  type: types.general.GET_THEMES_SUCCESS,
  payload,
});

const currentTheme = (payload) => ({
  type: types.general.CURRENT_THEME,
  payload,
});

const getSubscribe = (payload) => ({
  type: types.general.GET_PREMIUM_SUBSCRIBE,
  payload,
});

const getSubscribeSucces = (payload) => ({
  type: types.general.GET_PREMIUM_SUBSCRIBE_SUCCES,
  payload,
});

const getSubscribeRemove = (payload) => ({
  type: types.general.GET_PREMIUM_SUBSCRIBE_REMOVE,
  payload,
});

const getConfig = () => ({
  type: types.general.GET_CONFIG,
});

const getConfigSuccess = (payload) => ({
  type: types.general.GET_CONFIG_SUCCESS,
  payload,
});

const getPremiumPriceSuccess = (payload) => ({
  type: types.general.GET_PREMIUM_PRICE_SUCCESS,
  payload,
});

const getUsersAmounts = (payload) => ({
  type: types.general.GET_USERS_AMOUNT_SUCCESS,
  payload,
});

const getReadModal = (payload) => ({
  type: types.general.GET_READ_MODAL_INFO_EXAM,
  payload,
});

export default {
  getThemesRequest,
  getThemesSuccess,
  currentTheme,
  getSubscribe,
  getSubscribeSucces,
  getSubscribeRemove,
  getConfig,
  getConfigSuccess,
  getPremiumPriceSuccess,
  getReadModal,
  getUsersAmounts,
};
