import React from "react";
import Button from "../../common/Button/Button";

const ResetModal = ({ token, setOpenModalReset, setResetStatistics }) => {
  const closeModal = () => {
    setOpenModalReset(false);
    document.body.classList.remove("open");
  };

  const handleResetStatistics = () => {
    if (token) {
      setResetStatistics({ token });
    }
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <h2 className="title title--sm">
            Ви дійсно бажаєте скинути статистику до 0
          </h2>

          <div className="modal__btns">
            <Button btnText="Так" bg="yellow" onClick={handleResetStatistics} />
            <Button btnText="Ні" bg="red" onClick={closeModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetModal;
