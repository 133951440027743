const FinishIcon = ({ position }) => {

    const positionClass = position ? position : '';
    const classes = [positionClass].filter(Boolean).join(' ');

    return (
        <svg width="264" height="318" viewBox="0 0 264 318" fill="none" xmlns="http://www.w3.org/2000/svg" className={`prise ${classes}`}>
            <path d="M72.2279 132.01C71.5462 131.817 55.4645 127.046 29.0486 104.573C1.75764 81.3471 0.882439 42.9932 0.857193 41.3735C0.844937 40.7196 0.962284 40.0697 1.20247 39.4613C1.44265 38.8529 1.80092 38.298 2.25661 37.8287C2.71231 37.3594 3.25641 36.985 3.85755 36.7269C4.45869 36.4689 5.10498 36.3324 5.75914 36.3253L52.6117 35.791L52.7295 45.7066L11.166 46.1946C12.3442 56.6655 17.0273 81.3219 35.478 97.0219C59.9961 117.88 74.8324 122.436 74.9797 122.482L72.2279 132.01Z" fill="#FCAB28" />
            <path d="M191.772 132.01L189.046 122.474C189.168 122.436 204.004 117.88 228.522 97.022C246.973 81.322 251.664 56.6698 252.838 46.1947L211.292 45.7277L211.405 35.8121L258.241 36.3422C258.895 36.3493 259.541 36.4858 260.143 36.7438C260.744 37.0019 261.288 37.3763 261.743 37.8456C262.199 38.3149 262.557 38.8698 262.798 39.4782C263.038 40.0866 263.155 40.7365 263.143 41.3904C263.118 43.0101 262.247 81.3556 234.951 104.59C208.536 127.046 192.445 131.817 191.772 132.01Z" fill="#FCAB28" />
            <path d="M50.449 36.3422C50.449 36.3422 44.3899 34.1378 44.3899 28.0757C44.3899 22.0136 43.2875 19.2413 48.7953 13.7513C54.3032 8.26135 96.0013 0.714233 129.612 0.714233C163.223 0.714233 209.137 9.89782 214.654 14.8535C220.17 19.8092 217.961 33.0356 216.858 35.24C215.756 37.4444 211.346 36.8933 211.346 36.8933L50.449 36.3422Z" fill="#FFCF3F" />
            <path d="M129.246 19.2623C108.498 19.2623 64.7761 22.0178 48.7953 33.5867C48.7953 33.5867 48.7953 93.0844 65.3273 123.386C81.8592 153.688 111.061 178.496 111.061 178.496H150.735C150.735 178.496 179.94 153.705 196.472 123.407C213.004 93.1096 213 33.5867 213 33.5867C195.37 22.0178 149.994 19.2623 129.246 19.2623Z" fill="#FFD733" />
            <path d="M129.246 19.2623C108.498 19.2623 64.7761 22.0178 48.7953 33.5867C48.7953 33.5867 48.7953 93.0844 65.3273 123.386C73.1578 137.74 83.841 150.853 92.9002 160.684C99.8791 163.604 107.354 165.155 114.919 165.253C165.613 166.355 178.838 123.386 184.346 107.96C193.03 83.6568 197.97 45.4963 199.914 27.7854C178.611 21.0502 145.951 19.2623 129.246 19.2623Z" fill="#FFE266" />
            <path d="M187.653 252.848C182.696 237.977 172.223 240.177 172.223 240.177C172.223 240.177 172.223 235.398 164.877 234.708C160.337 233.312 148.639 228.819 144.68 218.689C139.719 206.018 141.924 190.04 141.924 190.04L130.904 188.605L119.884 190.04C119.884 190.04 122.085 206.026 117.128 218.689C113.164 228.819 101.463 233.312 96.9311 234.708C89.6014 235.394 89.5845 240.177 89.5845 240.177C89.5845 240.177 79.12 237.981 74.1423 252.848H187.653Z" fill="#FFCE45" />
            <path d="M89.5719 240.177C96.4613 239.734 103.349 239.437 110.236 239.285C117.124 239.1 124.012 239.054 130.9 239.029C137.788 239.004 144.676 239.1 151.564 239.281C158.452 239.462 165.335 239.731 172.223 240.177C165.334 240.626 158.447 240.926 151.564 241.078C144.676 241.258 137.788 241.309 130.9 241.33C124.012 241.351 117.124 241.258 110.236 241.073C103.348 240.888 96.4599 240.623 89.5719 240.177Z" fill="#FCAB28" />
            <path d="M150.735 186.191H111.603C110.432 186.191 109.308 185.725 108.479 184.897C107.651 184.068 107.185 182.945 107.185 181.773C107.185 180.602 107.651 179.478 108.479 178.65C109.308 177.822 110.432 177.356 111.603 177.356H150.735C151.907 177.356 153.03 177.822 153.859 178.65C154.687 179.478 155.153 180.602 155.153 181.773C155.153 182.945 154.687 184.068 153.859 184.897C153.03 185.725 151.907 186.191 150.735 186.191Z" fill="#FCAB28" />
            <path d="M148.547 191.752H113.833C113.309 191.769 112.787 191.681 112.297 191.492C111.807 191.303 111.361 191.018 110.984 190.653C110.607 190.288 110.307 189.852 110.102 189.369C109.898 188.886 109.792 188.366 109.792 187.842C109.792 187.317 109.898 186.798 110.102 186.315C110.307 185.832 110.607 185.395 110.984 185.03C111.361 184.666 111.807 184.38 112.297 184.192C112.787 184.003 113.309 183.914 113.833 183.932H148.547C149.071 183.914 149.594 184.003 150.083 184.192C150.573 184.38 151.019 184.666 151.396 185.03C151.773 185.395 152.073 185.832 152.278 186.315C152.483 186.798 152.588 187.317 152.588 187.842C152.588 188.366 152.483 188.886 152.278 189.369C152.073 189.852 151.773 190.288 151.396 190.653C151.019 191.018 150.573 191.303 150.083 191.492C149.594 191.681 149.071 191.769 148.547 191.752Z" fill="#FCAB28" />
            <path d="M192.95 252.297H72.7033C68.6296 252.297 65.3273 255.599 65.3273 259.672V310.482C65.3273 314.555 68.6296 317.857 72.7033 317.857H192.95C197.024 317.857 200.326 314.555 200.326 310.482V259.672C200.326 255.599 197.024 252.297 192.95 252.297Z" fill="#6C554F" />
            <path d="M172.665 263.647H92.9885C91.1016 263.647 89.5719 265.177 89.5719 267.063V294.829C89.5719 296.715 91.1016 298.245 92.9885 298.245H172.665C174.552 298.245 176.082 296.715 176.082 294.829V267.063C176.082 265.177 174.552 263.647 172.665 263.647Z" fill="#FCAB28" />
            <path d="M103.971 263.643H131.375C132.027 263.643 132.553 265.174 132.553 267.059V294.824C132.553 296.713 132.027 298.24 131.375 298.24H103.971C103.323 298.24 102.797 296.713 102.797 294.824V267.059C102.797 265.179 103.323 263.643 103.971 263.643Z" fill="#FFD733" />
            <path d="M111.061 178.475C114.368 178.24 117.675 178.126 120.978 178.055C124.281 177.983 127.593 177.933 130.9 177.92C134.207 177.907 137.51 177.945 140.817 178.05L145.778 178.21C147.428 178.307 149.081 178.362 150.735 178.492C149.081 178.622 147.428 178.681 145.778 178.774L140.817 178.934C137.51 179.039 134.203 179.056 130.9 179.068C127.597 179.081 124.285 179.039 120.978 178.934C117.671 178.829 114.368 178.715 111.061 178.475Z" fill="#FFE266" />
            <path d="M111.246 185.088C114.612 184.849 117.978 184.739 121.344 184.668C124.71 184.596 128.076 184.55 131.443 184.533C134.809 184.516 138.175 184.563 141.541 184.668L146.59 184.828C148.273 184.92 149.956 184.979 151.639 185.109C149.956 185.24 148.273 185.295 146.59 185.391L141.541 185.551C138.175 185.656 134.809 185.669 131.443 185.682C128.076 185.694 124.71 185.652 121.344 185.547C117.978 185.442 114.612 185.324 111.246 185.088Z" fill="#FFE266" />
            <path d="M120.263 192.434C127.333 191.665 134.466 191.665 141.537 192.434C138.006 192.844 134.454 193.035 130.9 193.006C127.346 193.031 123.793 192.84 120.263 192.434Z" fill="#FFE266" />
            <path d="M76.5323 247.523C85.5901 247.082 94.6506 246.785 103.714 246.631C112.777 246.446 121.836 246.399 130.9 246.374C139.963 246.349 149.022 246.446 158.081 246.626C167.141 246.807 176.204 247.077 185.267 247.523C176.204 247.974 167.142 248.274 158.081 248.423C149.022 248.604 139.959 248.654 130.9 248.675C121.841 248.696 112.777 248.604 103.714 248.419C94.6506 248.234 85.5915 247.968 76.5323 247.523Z" fill="#FCAB28" />
            <g opacity="0.48" className="prise__shine" >
                <path d="M101.421 2.18665C92.1639 3.08691 83.1889 4.33635 75.3332 5.75407C74.4201 32.9599 74.1298 78.4192 80.8032 103.551C90.6744 141.013 108.305 159.734 108.305 159.734C108.305 159.734 97.2846 104.624 98.387 56.1607C98.9172 32.9557 100.201 14.8114 101.421 2.18665Z" fill="#FFFFFD" />
            </g>
            <path d="M213 33.5867C206.899 30.0908 200.129 28.1178 193.333 26.5402C186.511 25.0214 179.616 23.8546 172.674 23.0443C165.731 22.2324 158.763 21.5887 151.782 21.1723C144.802 20.7558 137.813 20.4529 130.828 20.4234C123.843 20.394 116.855 20.5959 109.874 20.983C102.893 21.37 95.9213 21.9674 88.9786 22.7709C82.035 23.5579 75.1385 24.7165 68.3189 26.2415C61.5361 27.8149 54.7618 29.8552 48.7953 33.5867C51.655 31.5194 54.7655 29.8231 58.0522 28.5385C58.8685 28.2104 59.6763 27.8528 60.5053 27.5457L63.0046 26.7043C64.6877 26.1027 66.3708 25.6273 68.0538 25.1562C74.8617 23.3424 81.7756 21.9528 88.7556 20.9956C102.702 19.0425 116.771 18.0851 130.853 18.1307C144.933 18.2546 158.989 19.3057 172.93 21.2774C179.905 22.2564 186.816 23.6528 193.624 25.4591C195.332 25.8797 196.99 26.4435 198.673 26.9819C200.356 27.5204 202.001 28.1346 203.642 28.7741C205.284 29.4078 206.894 30.1239 208.464 30.9196C209.261 31.2921 210.029 31.7239 210.762 32.2111L213 33.5867Z" fill="#FCAB28" />
            <path className="prise__star" transform="rotate(-6.5973029,34.401484,29.663413);" d="M94.9493 45.6309L100.095 36.6156L104.909 45.8118L113.93 50.9568L104.732 55.7737L99.5862 64.789L94.7684 55.5928L85.7513 50.4478L94.9493 45.6309Z" fill="white">
            </path>


            <g opacity="0.35" className="prise__shine prise__shine--bottom">
                <path d="M78.4174 317.857H98.8372L149.839 252.297H126.372L78.4174 317.857Z" fill="white" />
            </g>
            <g opacity="0.35" className="prise__shine prise__shine--bottom">
                <path d="M128.834 317.857L179.414 252.297H169.825L118.719 317.857H128.834Z" fill="white" />
            </g>
        </svg>
    )
}

export default FinishIcon