export const forms = {
  0: "rectangle",
  1: "round",
  2: "triangular",
  3: "hexagon",
  4: "octagon",
  5: "triangular_rotate",
  6: "rhombus",
  7: "square",
};

export const signsNumber = {
  0: "first",
  1: "second",
  2: "third",
  3: "four",
  4: "fifth",
  5: "sixth",
  6: "seventh",
  7: "eight",
  8: "nine",
  9: "ten",
  10: "eleven",
  11: "twelve",
  12: "thirteen",
  13: "fourteen",
  14: "fifteen",
  15: "sixteen",
  16: "seventeen",
  17: "eighteen",
  18: "nineteen",
  19: "twenty",
};
