import { colorClassCards, colorProgressbarLine } from "./helpers";
import Progressbar from "../../hoc/progressbar";
import IconCard from "./IconCard";

export default function Card({
  theme,
  nextThemeId,
  currentTheme,
  selectedTheme,
  getCurrenTheme,
  idx,
}) {
  return (
    <div
      className={`card card-learn ${colorClassCards(
        theme,
        nextThemeId,
        currentTheme
      )} ${selectedTheme?.id === theme?.id ? "applied" : ""}`}
      onClick={getCurrenTheme}
      data-id={theme.id}
      data-status={theme.disabled || theme.blocked ? "close" : "open"}
    >
      <div className="card-learn__circle">
        <IconCard theme={theme} />
      </div>
      <h2 className="card-learn__title">Тема {idx + 1}</h2>
      <p className="card-learn__text">{theme.title}</p>
      <Progressbar
        otherClass="card-learn__progressbar"
        colorProcces={colorProgressbarLine(theme)}
        proccesNum={theme.progress}
      />
    </div>
  );
}
