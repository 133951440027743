import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchLogin({ payload }) {
  const response = yield API.auth.login(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(
        Actions.auth.getLoginSystemErrors("Неправильний логін і пароль")
      );
    } else {
      yield sessionStorage.setItem("token", response.data.access_token);
      yield put(Actions.auth.loginSuccess(response.data.access_token));
    }
  } else if (response.status === 401) {
    yield put(Actions.auth.getLoginSystemErrors("Неправильний логін і пароль"));
  } else {
    yield put(Actions.auth.getLoginErrors(response.data.errors));
  }
}

export function* watchLogout({ payload }) {
  const response = yield API.auth.logout(payload);

  if (response.status === 200) {
    if (response.data.status === "Не вдалося вийти") {
      yield put(Actions.popup.messageError("Error"));
    } else {
      yield sessionStorage.clear();
      yield put(Actions.auth.loginSuccess(null));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося вийти"));
  }
}

export function* watchGetUser({ payload }) {
  const response = yield API.auth.getUser(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Користувача не знайдено"));
    } else {
      yield sessionStorage.setItem("user", JSON.stringify(response.data));
      yield put(Actions.auth.getUserSuccess(response.data));
    }
  } else if (response.status === 401) {
    yield sessionStorage.removeItem("token");
    yield put(Actions.auth.loginSuccess(null));
    yield put(Actions.popup.messageError(response.data.message));
  } else {
    yield put(Actions.popup.messageError("Користувача не знайдено"));
  }
}
