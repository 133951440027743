import { starExamIcon } from './helpers';

export default function StarExam({
    selectedTheme,
    blocksDone,
    blocks,
    currentTheme,
    token,
    starClass,
    starCursorClass,
    position,
    onGetQuestions,
    setAllowOpenModal
}) {
    // ---------------- GET EXAM QUESTIONS ------------------------
    const getExamQuestions = (e) => {
        const status = e.target.dataset.status;

        if (status === 'open') {
            onGetQuestions({ id: currentTheme, token: token });
            sessionStorage.setItem("general", JSON.stringify({ type: 'all_theme', theme: currentTheme }));
            setAllowOpenModal(false)
            document.body.classList.remove('open');
        }
    }

    const starClasses = starClass && starClass;
    const starCursorClasses = (blocksDone?.length !== blocks?.length && starCursorClass) ? starCursorClass : '';
    const positionClass = (blocks?.length < 9 && position) ? position : '';
    const pulsClass = selectedTheme?.progress_car?.progress >= 0 && selectedTheme?.progress_car?.progress < 50 && blocksDone?.length === blocks?.length ? 'puls puls--star' : ''
    const classes = [starClasses, pulsClass, starCursorClasses, positionClass].filter(Boolean).join(' ');

    const status = blocksDone?.length === blocks?.length ? 'open' : 'close';

    return (
        <div className={`${classes}`}>
            <img
                src={starExamIcon(selectedTheme, blocks, blocksDone)}
                onClick={getExamQuestions}
                data-status={`${status}`}
                alt="Star picture"
                width="1"
                height="1"
            />
        </div>
    )
}
