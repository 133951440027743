import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import "./Modal.scss";

export default function ModalExam({
  setModalInfo,
  rules,
  token,
  onGetExamQuestions,
  questions,
}) {
  const history = useHistory();

  const closeModal = () => {
    setModalInfo(false);
    document.body.classList.remove("open");
  };

  useEffect(() => {
    if (questions?.length > 0) {
      history.push("/test");
      document.body.classList.remove("open");
    }
    // eslint-disable-next-line
  }, [questions]);

  const getQuestion = () => {
    onGetExamQuestions(token);
    sessionStorage.setItem("general", JSON.stringify({ type: "exam" }));
    sessionStorage.setItem("read", true);
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <ul className="modal-rules">
            {rules?.rule.map((r) => {
              return (
                <li className="modal-rules__item" key={r}>
                  {r}
                </li>
              );
            })}
          </ul>
          <div className="modal__btn">
            <Button
              btnText="Зрозуміло, більше не показувати"
              bg="yellow"
              size="sm"
              rounded="md"
              onClick={getQuestion}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
