import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchResetPassword({ payload }) {
  const response = yield API.recover.resetPassword(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(
        Actions.resetPassword.resetPasswordErrorsSystem(response.data.message)
      );
    } else {
      yield put(Actions.popup.messageSuccess("Пароль успішно змінено!"));
      yield put(
        Actions.resetPassword.setPremiumExpired(response.data.premium_expired)
      );
      yield put(
        Actions.resetPassword.resetPasswordSuccess(response.data.success)
      );
    }
  } else if (response.status === 500) {
    yield put(
      Actions.resetPassword.resetPasswordErrorsSystem("Сторінка в розробці")
    );
  } else if (response.status === 422) {
    yield put(Actions.resetPassword.resetPasswordErrors(response.data.errors));
  } else {
    yield put(
      Actions.resetPassword.resetPasswordErrorsSystem(response.data.message)
    );
  }
}
