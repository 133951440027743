export default {
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_BUY_REQUEST: "REGISTER_BUY_REQUEST",

  REGISTER_SUCCESS_LINK: "REGISTER_SUCCESS_LINK",
  REGISTER_ERRORS: "REGISTER_ERRORS",
  REGISTER_SYSTEM_ERRORS: "REGISTER_SYSTEM_ERRORS",

  REGISTER_CATEGORIES_REQUEST: "REGISTER_CATEGORIES_REQUEST",
  REGISTER_CATEGORIES_SUCCESS: "REGISTER_CATEGORIES_SUCCESS",

  REGISTER_SCHOOL_REQUEST: "REGISTER_SCHOOL_REQUEST",
  REGISTER_SCHOOL_SUCCESS: "REGISTER_SCHOOL_SUCCESS",
  SCHOOLS_AUTOCOMPLETE_SUCCESS: "SCHOOLS_AUTOCOMPLETE_SUCCESS",

  REGISTER_GROUP_REQUEST: "REGISTER_GROUP_REQUEST",
  RREGISTER_GROUP_SUCCESS: "RREGISTER_GROUP_SUCCESS",
};
