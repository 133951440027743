import React from "react";
import { connect } from "react-redux";
import Learn from "../components/Learn/Learn";
import Actions from "../redux/actions/index";

export const LearnHOC = (props) => {
    return <Learn {...props} />;
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    themes: state.general.themes,
    currentTheme: state.general.currentTheme,
    questions: state.test.questions,
    blocks: state.map.blocks,
    currentBlock: state.map.currentBlock,
    empty: state.test.empty,
    token: state.auth.token,
    failedTheme: state.test.failedTheme,
});

const mapDispatchToProps = (dispatch) => ({
    setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
    onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
    // ---------------- THEME --------------------
    onGetThemes: (data) => dispatch(Actions.general.getThemesRequest(data)),
    onCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
    // ---------------- BLOCKS --------------------
    onGetBlocks: (data) => dispatch(Actions.map.getBlocksRequest(data)),
    onCurrentBlock: (data) => dispatch(Actions.map.getCurrentBlock(data)),
    // ----------------------- GET QUESTIONS -------------------------------
    onGetQuestions: (data) => dispatch(Actions.test.getQuestionsRequest(data)),
    onGetExamQuestions: (data) => dispatch(Actions.test.getExamQuestionsRequest(data)),
    onGetRetestQuestion: (data) => dispatch(Actions.test.getRetestQuestionsRequest(data)),
    onGetBlockQuestions: (data) => dispatch(Actions.test.getBlockQuestionsRequest(data)),
    // ------------------- RESET RESULTS -------------------------
    onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
    onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
    onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),

    onSetEmptyData: (data) => dispatch(Actions.test.getEmptyData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearnHOC);