import types from "../types/index";

const getRepeatThemesRequest = (payload) => ({
  type: types.repeat.REPEAT_THEMES_REQUEST,
  payload,
});

const getRepeatThemesSuccess = (payload) => ({
  type: types.repeat.REPEAT_THEMES_SUCCESS,
  payload,
});

const getRepeatThemesError = (payload) => ({
  type: types.repeat.REPEAT_THEMES_ERROR,
  payload,
});

const getRepeatThemesCountRequest = (payload) => ({
  type: types.repeat.REPEAT_THEMES_COUNT_REQUEST,
  payload,
});

const getRepeatThemesCountSuccess = (payload) => ({
  type: types.repeat.REPEAT_THEMES_COUNT_SUCCESS,
  payload,
});

const getRepeatThemesCountError = (payload) => ({
  type: types.repeat.REPEAT_THEMES_COUNT_ERROR,
  payload,
});

export default {
  getRepeatThemesRequest,
  getRepeatThemesSuccess,
  getRepeatThemesError,
  getRepeatThemesCountRequest,
  getRepeatThemesCountSuccess,
  getRepeatThemesCountError,
};
