import React from "react";

const Burger = () => {
  return (
    <div className="burger-learn">
      <div className="burger-learn__line burger-learn__line--first"></div>
      <div className="burger-learn__line burger-learn__line--second"></div>
      <div className="burger-learn__line burger-learn__line--third"></div>
    </div>
  );
};

export default Burger;
