import InputMask from "react-input-mask";
import classNames from "classnames";
import "../field.scss";
import "../input.scss";

export default function FieldPhone({
  labelText,
  name,
  id,
  forLabel,
  fieldClass,
  value,
  inputSize,
  inputColor,
  type,
  inputClasses,
  ...props
}) {
  return (
    <InputMask mask="+38 (099) 99 99 999" maskChar="_" value={value}>
      {(inputProps) => {
        return (
          <div
            className={classNames("field", {
              [fieldClass]: fieldClass,
            })}
            {...inputProps}
          >
            <label htmlFor={forLabel || ""} className="field__title">
              {labelText}
            </label>
            <input
              {...props}
              className={classNames("input", {
                "input--sm": inputSize === "sm",
                "input--white": inputColor === "white",
                [inputClasses]: inputClasses,
              })}
              type={type || "text"}
              name={name || ""}
              id={id || ""}
              {...props}
            />
          </div>
        );
      }}
    </InputMask>
  );
}
