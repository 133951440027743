import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import Finish from "../components/Finish/Finish";

export const FinishHOC = (props) => {
    return <Finish {...props} />;
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishHOC);