import types from "../types/index"

const getTermPageData = () => ({
    type: types.agreement.AGREEMENT_TERM,
})

const getTermPageDataSuccess = (payload) => ({
    type: types.agreement.AGREEMENT_TERM_SUCCESS,
    payload,
})

export default {
    getTermPageData,
    getTermPageDataSuccess,
};