export default {
    GET_FREE_PREMIUM_REQUEST: "GET_FREE_PREMIUM_REQUEST",
    GET_FREE_PREMIUM_SUCCESS: "GET_FREE_PREMIUM_SUCCESS", 
    GET_FREE_PREMIUM_ERRORS: "GET_FREE_PREMIUM_ERRORS", 

    GET_FREE_PREMIUM_SUBSCRIBE_REQUEST: "GET_FREE_PREMIUM_SUBSCRIBE_REQUEST",
    GET_FREE_PREMIUM_SUBSCRIBE_SUCCESS: "GET_FREE_PREMIUM_SUBSCRIBE_SUCCESS", 
    GET_FREE_PREMIUM_SUBSCRIBE_ERRORS: "GET_FREE_PREMIUM_SUBSCRIBE_ERRORS", 
    GET_FREE_PREMIUM_SUBSCRIBE_MESSAGE: "GET_FREE_PREMIUM_SUBSCRIBE_MESSAGE", 

    GET_FEEDBACK_REQUEST: "GET_FEEDBACK_REQUEST",
    GET_FEEDBACK_SUCCES: "GET_FEEDBACK_SUCCES",
    GET_FEEDBACK_ERRORS: "GET_FEEDBACK_ERRORS",
};