import React from "react";
import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Sidebar from "../../hoc/sidebar";
import Button from "../../common/Button/Button";
import ResultContent from "./ResultContent";

const Result = ({
  token,
  onSetThemes,
  onSetCurrentTheme,
  onSetQuestions,
  onLogout,
  error,
  success,
  sendResult,
  setLogin,
  onGetThemes,
  onGetExamQuestions,
  failedTheme,
}) => {
  // -------------------- GET RESULT ------------------
  const countResult = JSON.parse(sessionStorage.getItem("result"));
  const general = JSON.parse(sessionStorage.getItem("general"));
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  const timer = JSON.parse(sessionStorage.getItem("timer"));
  const user = JSON.parse(sessionStorage.getItem('user'));
  // ------------- GET HISTORY -------------------
  const history = useHistory();
  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };

  useEffect(() => {
    if(token) {
      onGetThemes(token);
    }
  }, [token]);

  // -------------- LOGOUT FUNCTION ----------------
  // -------------- LOGOUT ----------------
  const logoutUser = () => {
    onLogout(token);
    sessionStorage.clear("token");
    setLogin(null)
    history.push('/')
  };

  // -------- INFINITE SENT RESULT -------------
  React.useEffect(() => {
    let infinite;
    if (error !== null) {
      infinite = setInterval(() => {
        let leftTime = 20 * 60 - timer;
        sendResult({
          info: {
            type: general.type,
            theme_id: general.theme,
            result: countResult.result.length,
            time: leftTime,
            data_question: JSON.stringify(answers),
            count_question: countResult.count,
            idx: general.block
          },
          token,
        });
      }, 5000);
    }
    success !== null && clearInterval(infinite);
  }, [error, success]);

  const definedType =
    general?.type === "all_theme"
      ? (countResult?.count * 90) / 100
      : general?.type === "retest" ? countResult?.count : countResult?.count - 2;

  const correctCount = answers?.filter(answer => { return answer.correct === 1 }).length;

  const setExamQuestion = () => {
    if(token) {
      resetTest();
      onGetExamQuestions(token);
      sessionStorage.setItem("general", JSON.stringify({ type: 'exam' }));
      history.push('/test');
    }
  }
  const setExamHeadContent = () => {
    if((correctCount || 0) < definedType) {
      if(user.premium_check) {
        return <ResultContent
          title='Іспит не складено! Рекомендуємо пройти ще раз теми в яких Ви допускаєте помилки!'
          subtitle={false}
          correctCount={correctCount}
          answers={answers}
          general={general}
          resetTest={resetTest}
          setExamQuestion={setExamQuestion}
          failed={true}
          btnAgain={true}
        />;
      } else {
        return <ResultContent
          title='Іспит не складено!'
          subtitle={true}
          correctCount={correctCount}
          answers={answers}
          general={general}
          resetTest={resetTest}
          setExamQuestion={setExamQuestion}
          failed={true}
          btnAgain={false}
        />
      }
    } else {
      if(user.premium_check) {
        if(correctCount === answers?.length) {
          return <ResultContent 
            title="Іспит успішно складено!"
            subtitle={false}
            correctCount={correctCount}
            answers={answers}
            general={general}
            resetTest={resetTest}
            logoutUser={logoutUser}
            failed={false}
            btnAgain={false}
          />;
        } else {
          return <ResultContent 
            title="Іспит успішно складено!"
            subtitle={false}
            correctCount={correctCount}
            answers={answers}
            general={general}
            resetTest={resetTest}
            setExamQuestion={setExamQuestion}
            failed={false}
            btnAgain={true}
          />;
        }
      } else {
        return <ResultContent 
          title="Іспит успішно складено!"
          subtitle={true}
          correctCount={correctCount}
          answers={answers}
          general={general}
          resetTest={resetTest}
          setExamQuestion={setExamQuestion}
          failed={false}
          btnAgain={false}
        />;
      }
    }
  }

  return (
    <div className="row">
      <Sidebar />
      <main className="content">
        <div className="content__inner">
          <div className="result page-block">
            {general?.type === 'exam' && setExamHeadContent()}
            {(failedTheme.length > 0 && user.premium_check) && <div className="result__errors errors-block">
              <div className="errors-block-title">{(correctCount || 0) < definedType ? 'А саме:' : 'Ви допустили помилки в темах:'}</div>
              <ul>
                {failedTheme.map(theme => <li key={theme.id}>Тема {theme.id}. <span>{theme.title}</span></li>)}
              </ul>
            </div>}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Result;
