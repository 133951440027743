import classNames from "classnames";
import sprites from "../../../assets/img/sprites.svg";
import "./error.scss";

function Error({ errorText, size }) {
  return (
    <span
      className={classNames("error-message", {
        "error-message--lg": size === "lg",
      })}
    >
      <svg className="icon">
        <use xlinkHref={`${sprites}#cancel`}></use>
      </svg>
      {errorText}
    </span>
  );
}

export default Error;
