import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Title from "../../common/Title/Title";
import RecoverForm from "./RecoverForm";
import Header from "../../hoc/header";
import Footer from "../../hoc/footer";

export default function Recover({
  onRecover,
  setRecover,
  errors,
  errorsSystem,
  token,
  setErrors,
  setErrorsSystem,
}) {
  const history = useHistory();

  useEffect(() => {
    setRecover(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (token) {
      history.push("/reset_password");
      setRecover(null);
      setErrors({});
      setErrorsSystem("");
    }
  }, [token]);

  return (
    <div className="main">
      <Header />

      <main className="main__container">
        <div className="auth container">
          <div className="auth__container">
            <Title
              title="Проходження тестів на знання Правил Дорожнього руху України"
              titlesize="sm"
              titleClasses="auth__title"
              titleTag="h1"
              subtitle="Введіть номер телефону для відновлення паролю"
              subtitleColor="green"
              position="center"
            />
            <RecoverForm
              onRecover={onRecover}
              setErrors={setErrors}
              setErrorsSystem={setErrorsSystem}
              errors={errors}
              errorsSystem={errorsSystem}
            />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
