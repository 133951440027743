import React from "react";
import { connect } from "react-redux";
import Header from "../components/Header/Header";
import Actions from "../redux/actions";

export const HeaderHOC = (props) => {
    return <Header {...props} />
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.user,
    totalProgress: state.map.totalProgress,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
    onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
    setUser: (data) => dispatch(Actions.auth.getUserSuccess(data)),
    setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),

    // ---------------- TOTAL PROGRESS --------------------
    onGetTotalProgress: (data) => dispatch(Actions.map.getTotalProgressRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHOC)
