import React from "react";
import { connect } from "react-redux";
import ModalPay from "../common/Modal/ModalPay";
import Actions from "../redux/actions/index";

export const ModalPayHOC = (props) => {
  return <ModalPay {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  subscribe: state.general.subscribe,
  price: state.general.price,
});

const mapDispatchToProps = (dispatch) => ({
  setSubscribe: (data) => dispatch(Actions.general.getSubscribe(data)),
  removeSubscribe: (data) => dispatch(Actions.general.getSubscribeRemove(data)),

  setPrice: () => dispatch(Actions.general.getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayHOC);
