import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import AuthPage from "./pages/auth";
// import RegisterLitePage from "./pages/registerLite";
import TestPage from "./pages/test";
import ResultPage from "./pages/result";
import GeneralPage from "./pages/general";
import MyTestsPage from "./pages/myTests";
import AgreementPage from "./pages/agreement";
import StartedLearn from "./pages/startedLearn";
import Finish from "./pages/finish";
import RecoverPage from "./pages/recover";
import ResetPasswordPage from "./pages/resetPassword";
import LearnPage from "./pages/learn";
// import RegisterPremiumPage from "./pages/registerPremium";
import PremiumPage from "./pages/premium";
import MainPage from "./pages/main";
import AboutPage from "./pages/about";
import QrCodePage from "./pages/qrcode";
import ProfilePage from "./pages/profile";
import ProfileEditPage from "./pages/profileEdit";
import TheoryPage from "./pages/theory";
import RegisterPage from "./pages/register";
import RepetitionPage from "./pages/repetition";

export const Main = () => {
  return (
    <HashRouter>
      <LastLocationProvider>
        <Switch>
          <Route exact path="/" component={MainPage} />

          <Route exact path="/login" component={AuthPage} />
          <Route exact path="/register" component={RegisterPage} />
          {/* <Route
            exact
            path="/register/premium"
            component={RegisterPremiumPage}
          /> */}
          <Route exact path="/recover" component={RecoverPage} />
          <Route exact path="/reset_password" component={ResetPasswordPage} />

          <Route exact path="/general" component={GeneralPage} />
          <Route exact path="/my_test" component={MyTestsPage} />
          <Route exact path="/result" component={ResultPage} />

          <Route exact path="/test" component={TestPage} />
          <Route exact path="/agreement" component={AgreementPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/started_learn" component={StartedLearn} />
          <Route exact path="/finish" component={Finish} />
          <Route exact path="/learn" component={LearnPage} />
          <Route exact path="/premium" component={PremiumPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/profile/edit" component={ProfileEditPage} />
          <Route exact path="/repetition" component={RepetitionPage} />
          <Route exact path="/theory" component={TheoryPage} />

          <Route exact path="/referal" component={QrCodePage} />
        </Switch>
      </LastLocationProvider>
    </HashRouter>
  );
};
