import types from "../types/index";

const sendResultRequest = (payload) => ({
  type: types.result.SEND_RESULT_REQUEST,
  payload,
});

const sendBadResultRequest = (payload) => ({
  type: types.result.SEND_BAD_RESULT_REQUEST,
  payload,
});

const setFailedTheme = (payload) => ({
  type: types.result.SET_FAILED_THEME,
  payload,
});

export default {
  sendResultRequest,
  sendBadResultRequest,
  setFailedTheme,
};
