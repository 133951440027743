import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Progress from "../../hoc/progress";
import ProfileUser from "./ProfileUser";

import "./profile.scss";

const Profile = ({ user }) => {
  return (
    <div className="main">
      <Header />

      <main className="main__container">
        <div className="profile container">
          <ProfileUser user={user} />
          {user.premium_check && <Progress />}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Profile;
