import types from "../types/index";

const initialState = {
    blocks: [],
    totalProgress: {},
    currentBlock: 0,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.map.GET_BLOCKS_SUCCESS:
            return {
                ...state,
                blocks: payload,
            };

        case types.map.GET_TOTAL_PROGRESS_SUCCESS:
            return {
                ...state,
                totalProgress: payload,
            };

        case types.map.GET_CURRENT_BLOCK:
            return {
                ...state,
                currentBlock: payload
            }
        default:
            return state;
    }
};

export default reducer;