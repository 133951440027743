import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetThemes({ payload }) {
  const response = yield API.general.getThemes(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося підгрузити теми"));
    } else {
      yield put(Actions.general.getThemesSuccess(response.data.theme));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося підгрузити теми"));
  }
}

export function* watchGetSubscribe({ payload }) {
  const response = yield API.general.subscribe(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося підписатись"));
    } else {
      yield put(Actions.general.getSubscribeSucces(response.data.link));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося підписатись"));
  }
}

export function* watchGetConfig() {
  const response = yield API.general.config();

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося отримати ціну"));
    } else {
      yield put(
        Actions.general.getPremiumPriceSuccess(response.data.subscription.price)
      );
      yield put(Actions.general.getUsersAmounts(response.data.users.students));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося отримати ціну"));
  }
}
