import Button from "../Button/Button";
import "./Modal.scss";

export default function ModalHint({ setModalHint, notes }) {
  const closeModal = () => {
    setModalHint(false);
  };

  return (
    <div className="modal modal-hint">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal-hint__container">
        <div className="modal-hint__content">
          <div dangerouslySetInnerHTML={{ __html: notes }}></div>
          <div className="modal-hint__btn">
            <Button
              btnText="Закрити"
              bg="yellow"
              size="sm"
              rounded="md"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
