import "./head.scss";

function Head({ title, subtitle }) {
  return (
    <div className="head">
      <h1 className="head__title">{title}</h1>
      <p className="head__subtitle">{subtitle}</p>
    </div>
  );
}

export default Head;
