import "./label.scss";

const Label = ({ color, text, uppercase }) => {
  return (
    <span
      className={`label label--${color} ${uppercase ? "label--uppercase" : ""}`}
    >
      {text}
    </span>
  );
};

export default Label;
