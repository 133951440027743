const Hint = () => {
    return (
        <svg width="45" height="72" viewBox="0 0 45 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="hint-icon">
            <g clipPath="url(#clip0_456_2007)">
                <path className="hint-icon__gray" d="M44.8484 23.7123C33.2967 39.2265 29.0388 25.8228 22.6543 25.8682C19.3081 25.8908 16.8785 39.841 0.457785 23.7123C0.426739 20.7373 0.977908 17.7856 2.0794 15.0279C3.1809 12.2703 4.81085 9.76144 6.87495 7.64658C8.93906 5.53171 11.3963 3.8528 14.1046 2.70698C16.8129 1.56117 19.7184 0.971191 22.6531 0.971191C25.5877 0.971191 28.4932 1.56117 31.2015 2.70698C33.9098 3.8528 36.3671 5.53171 38.4312 7.64658C40.4953 9.76144 42.1253 12.2703 43.2267 15.0279C44.3282 17.7856 44.8794 20.7373 44.8484 23.7123Z" fill="#E8AF2E" />
                <path className="hint-icon__gray" d="M0.456543 23.3417C2.89087 37.2589 9.57781 38.9618 13.2243 53.5082C21.1458 58.8089 20.5372 57.5855 33.2926 54.5544C35.1134 41.1639 44.8432 33.0817 44.8432 23.3442C45.4518 8.77275 2.28229 11.6266 0.456543 23.3417Z" fill="#E8AF2E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.5978 27.3799C12.8921 27.2469 13.2347 27.3866 13.3628 27.6921L12.8299 27.9329L12.297 28.1738C12.1688 27.8684 12.3035 27.5129 12.5978 27.3799ZM12.8299 27.9329C12.297 28.1738 12.297 28.1737 12.297 28.1738L12.3422 28.2817C12.3719 28.3525 12.4155 28.4568 12.4716 28.5909C12.5836 28.8592 12.745 29.2464 12.9425 29.7223C13.3375 30.6741 13.8766 31.98 14.4531 33.3966C15.6093 36.2371 16.9067 39.4999 17.501 41.2554C18.0723 42.9426 18.3823 45.87 18.5444 48.4417C18.6246 49.7139 18.6676 50.8768 18.6905 51.7217C18.7019 52.144 18.7083 52.4865 18.7119 52.723C18.7136 52.8412 18.7147 52.933 18.7153 52.9949C18.7156 53.0259 18.7158 53.0494 18.7159 53.0651L18.7161 53.0879C18.7179 53.421 18.9795 53.6898 19.3005 53.688C19.6216 53.6861 19.8804 53.4146 19.8786 53.0814L19.2973 53.0848C19.8786 53.0814 19.8786 53.0816 19.8786 53.0814L19.8784 53.0556C19.8783 53.039 19.8781 53.0145 19.8778 52.9825C19.8771 52.9186 19.876 52.8247 19.8742 52.7042C19.8706 52.4633 19.8641 52.1157 19.8525 51.6878C19.8294 50.8324 19.7859 49.654 19.7045 48.363C19.5434 45.8082 19.2294 42.7209 18.5978 40.8555C17.9893 39.0583 16.677 35.7598 15.5241 32.9272C14.9461 31.5073 14.4059 30.1987 14.0102 29.2451C13.8123 28.7683 13.6505 28.3802 13.5382 28.1112C13.482 27.9767 13.4382 27.872 13.4084 27.8009L13.3628 27.6921C13.3628 27.692 13.3628 27.6921 12.8299 27.9329Z" fill="#403F41" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.7578 27.3798C35.0785 27.5126 35.2254 27.8679 35.086 28.1734L34.5054 27.9329L33.9248 27.6924C34.0642 27.3869 34.4371 27.2469 34.7578 27.3798ZM33.9248 27.6924C33.9248 27.6923 33.9248 27.6924 34.5054 27.9329C35.086 28.1734 35.086 28.1734 35.086 28.1734L35.0858 28.1739L35.0368 28.2813C35.0046 28.3521 34.9571 28.4565 34.8961 28.5906C34.7743 28.8589 34.5987 29.2462 34.3838 29.7221C33.9539 30.674 33.367 31.98 32.7386 33.3967C31.4788 36.2369 30.0618 39.5011 29.4036 41.2582C28.7722 42.9436 28.4345 45.8693 28.2604 48.4412C28.1744 49.7132 28.1295 50.8758 28.1062 51.7206C28.0945 52.1429 28.0883 52.4853 28.0849 52.7217C28.0833 52.84 28.0823 52.9317 28.0818 52.9936C28.0815 53.0245 28.0814 53.0481 28.0813 53.0637L28.0812 53.0865C28.0801 53.4196 27.7957 53.689 27.446 53.688C27.0964 53.6869 26.8138 53.416 26.8149 53.0828L27.4481 53.0848C26.8149 53.0828 26.8149 53.083 26.8149 53.0828L26.8151 53.057C26.8152 53.0404 26.8153 53.0158 26.8156 52.9838C26.8161 52.9199 26.8171 52.826 26.8188 52.7055C26.8222 52.4645 26.8286 52.1169 26.8404 51.6889C26.864 50.8334 26.9094 49.6548 26.9968 48.3635C27.1697 45.8089 27.5116 42.7199 28.211 40.8527C28.8837 39.0571 30.3157 35.76 31.5722 32.9271C32.202 31.5073 32.79 30.1988 33.2206 29.2453C33.4359 28.7686 33.6119 28.3805 33.7341 28.1115C33.7952 27.977 33.8428 27.8723 33.8752 27.8012L33.9248 27.6924Z" fill="#403F41" />
                <path d="M15.7168 60.6244V67.3079C15.7168 69.3636 17.0831 71.029 18.7698 71.029H27.9289C29.6106 71.029 30.9769 69.3636 30.9769 67.3079V60.6244H15.7168Z" fill="#A6A8AB" />
                <path d="M11.5547 51.6121V62.9645C11.5544 63.4767 11.6558 63.9839 11.8531 64.4572C12.0505 64.9305 12.34 65.3607 12.705 65.7231C13.07 66.0855 13.5035 66.373 13.9806 66.5693C14.4577 66.7656 14.9692 66.8668 15.4858 66.8672H31.2074C31.724 66.8668 32.2355 66.7656 32.7126 66.5693C33.1897 66.373 33.6231 66.0855 33.9882 65.7231C34.3532 65.3607 34.6427 64.9305 34.8401 64.4572C35.0374 63.9839 35.1388 63.4767 35.1385 62.9645V51.6071L11.5547 51.6121Z" fill="#221F1F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.9197 29.715C15.5854 30.1516 15.3634 30.7775 15.3634 31.4901C15.3634 32.2029 15.5854 32.8294 15.9199 33.2668C16.2542 33.7039 16.6782 33.9294 17.1042 33.9294C17.5302 33.9294 17.9541 33.7039 18.2884 33.2668C18.6229 32.8294 18.8449 32.2029 18.8449 31.4901C18.8449 30.7789 18.623 30.1529 18.2885 29.7158C17.9542 29.2789 17.5302 29.0534 17.1042 29.0534C16.6778 29.0534 16.2539 29.2786 15.9197 29.715ZM15.0978 29.0901C15.5815 28.4583 16.2864 28.0232 17.1042 28.0232C17.9222 28.0232 18.6271 28.4593 19.1106 29.0911C19.5939 29.7227 19.8787 30.5727 19.8787 31.4901C19.8787 32.4086 19.594 33.2593 19.1107 33.8913C18.6272 34.5235 17.9223 34.9596 17.1042 34.9596C16.286 34.9596 15.5811 34.5235 15.0977 33.8913C14.6143 33.2593 14.3296 32.4086 14.3296 31.4901C14.3296 30.5716 14.6144 29.7215 15.0978 29.0901Z" fill="#403F41" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.0807 29.7143C19.7465 30.1504 19.5246 30.7761 19.5246 31.4901C19.5246 32.2039 19.747 32.8303 20.0817 33.2673C20.4163 33.7041 20.8407 33.9294 21.267 33.9294C21.6929 33.9294 22.1166 33.7039 22.4508 33.2668C22.7851 32.8294 23.007 32.2029 23.007 31.4901C23.007 30.7785 22.7856 30.1526 22.4517 29.7157C22.118 29.2791 21.6943 29.0534 21.267 29.0534C20.8389 29.0534 20.4146 29.2786 20.0807 29.7143ZM19.2596 29.0888C19.7438 28.4571 20.4491 28.0232 21.267 28.0232C22.0858 28.0232 22.7905 28.4591 23.2736 29.0913C23.7564 29.723 24.0403 30.5731 24.0403 31.4901C24.0403 32.4086 23.7557 33.2593 23.2726 33.8913C22.7894 34.5235 22.0848 34.9596 21.267 34.9596C20.4498 34.9596 19.7446 34.5246 19.2605 33.8926C18.7766 33.2609 18.4912 32.4101 18.4912 31.4901C18.4912 30.5705 18.7758 29.7202 19.2596 29.0888Z" fill="#403F41" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2429 29.7151C23.9086 30.1517 23.6867 30.7775 23.6867 31.4901C23.6867 32.2025 23.909 32.829 24.2439 33.2665C24.5787 33.7038 25.0032 33.9294 25.4292 33.9294C25.855 33.9294 26.2787 33.7039 26.6129 33.2668C26.9472 32.8294 27.1691 32.2029 27.1691 31.4901C27.1691 30.7785 26.9477 30.1526 26.6138 29.7157C26.2801 29.2791 25.8564 29.0534 25.4292 29.0534C25.0014 29.0534 24.577 29.2789 24.2429 29.7151ZM23.4216 29.0899C23.9056 28.458 24.6109 28.0232 25.4292 28.0232C26.2479 28.0232 26.9526 28.4591 27.4357 29.0913C27.9186 29.723 28.2025 30.5731 28.2025 31.4901C28.2025 32.4086 27.9178 33.2593 27.4347 33.8913C26.9515 34.5235 26.2469 34.9596 25.4292 34.9596C24.6116 34.9596 23.9064 34.5236 23.4225 33.8916C22.9387 33.2596 22.6533 32.4089 22.6533 31.4901C22.6533 30.5716 22.938 29.7214 23.4216 29.0899Z" fill="#403F41" />
                <path fillRule="evenodd" clipRule="evenodd" d="M29.0984 29.7151C28.764 30.1517 28.5421 30.7775 28.5421 31.4901C28.5421 32.2025 28.7645 32.829 29.0994 33.2665C29.4342 33.7038 29.8587 33.9294 30.2846 33.9294C30.7104 33.9294 31.1342 33.7039 31.4683 33.2668C31.8027 32.8294 32.0246 32.2029 32.0246 31.4901C32.0246 30.7785 31.8032 30.1526 31.4693 29.7157C31.1356 29.2791 30.7119 29.0534 30.2846 29.0534C29.8568 29.0534 29.4325 29.2789 29.0984 29.7151ZM28.2771 29.0899C28.7611 28.458 29.4663 28.0232 30.2846 28.0232C31.1034 28.0232 31.8081 28.4591 32.2912 29.0913C32.774 29.723 33.0579 30.5731 33.0579 31.4901C33.0579 32.4086 32.7733 33.2593 32.2902 33.8913C31.8069 34.5235 31.1024 34.9596 30.2846 34.9596C29.467 34.9596 28.7619 34.5236 28.278 33.8916C27.7942 33.2596 27.5088 32.4089 27.5088 31.4901C27.5088 30.5716 27.7934 29.7214 28.2771 29.0899Z" fill="#403F41" />
                <path className="hint-icon__shine" d="M15.9421 5.60511C21.3516 1.55509 31.0346 5.00022 34.6295 7.63385C39.6087 11.298 43.2847 19.3856 42.7093 24.6131C42.134 29.8405 39.9505 32.6011 37.9195 37.4676C37.199 39.2101 40.3783 32.1929 36.5105 22.7511C33.6042 15.709 25.2293 13.9068 19.6771 13.2571C15.7921 12.8016 10.5498 9.63522 15.9421 5.60511Z" fill="#FDE039" />
                <path d="M35.8323 55.2088C35.8357 55.3535 35.7817 55.4938 35.6821 55.5988C35.5825 55.7038 35.4455 55.7649 35.301 55.7689H11.3902C11.2462 55.7643 11.1098 55.7028 11.0108 55.5979C10.9117 55.493 10.8581 55.3531 10.8615 55.2088V54.2481C10.8581 54.1038 10.9117 53.9639 11.0108 53.859C11.1098 53.7541 11.2462 53.6926 11.3902 53.688H35.301C35.4455 53.692 35.5825 53.7532 35.6821 53.8581C35.7817 53.9631 35.8357 54.1034 35.8323 54.2481V55.2088Z" fill="#403F41" />
                <path d="M35.8323 58.6803C35.8357 58.8242 35.7817 58.9636 35.6821 59.0679C35.5825 59.1723 35.4455 59.2331 35.301 59.2371H11.3902C11.2462 59.2324 11.1098 59.1713 11.0108 59.0671C10.9117 58.9628 10.8581 58.8238 10.8615 58.6803V57.7129C10.8581 57.5694 10.9117 57.4304 11.0108 57.3261C11.1098 57.2218 11.2462 57.1607 11.3902 57.1561H35.301C35.4455 57.1601 35.5825 57.2209 35.6821 57.3252C35.7817 57.4296 35.8357 57.569 35.8323 57.7129V58.6803Z" fill="#403F41" />
                <path d="M35.8323 62.1479C35.8357 62.292 35.7817 62.4315 35.6821 62.536C35.5826 62.6405 35.4455 62.7014 35.301 62.7053H11.3927C11.2482 62.7014 11.1112 62.6405 11.0116 62.536C10.9121 62.4315 10.8581 62.292 10.8615 62.1479V61.1818C10.8581 61.0377 10.9121 60.8982 11.0116 60.7937C11.1112 60.6892 11.2482 60.6284 11.3927 60.6244H35.301C35.4455 60.6284 35.5826 60.6892 35.6821 60.7937C35.7817 60.8982 35.8357 61.0377 35.8323 61.1818V62.1479Z" fill="#403F41" />
            </g>
            <defs>
                <clipPath id="clip0_456_2007">
                    <rect width="44.3931" height="70.0579" fill="white" transform="translate(0.456543 0.971069)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Hint;