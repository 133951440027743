import types from "../types/index";

const initialState = {
  token: null,
  user: {},
  errors: {},
  systemError: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.auth.LOGIN_SUCCESS:
      return {
        ...state,
        token: payload,
      };
    case types.auth.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case types.auth.LOGIN_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case types.auth.LOGIN_SYSTEM_ERROR:
      return {
        ...state,
        systemError: payload,
      };
    default:
      return state;
  }
};

export default reducer;
