import React from "react";
import { connect } from "react-redux";
import Progressbar from "../common/Progressbar/Progressbar";
import Actions from "../redux/actions/index";

export const ProgressbarHOC = (props) => {
    return <Progressbar {...props} />;
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressbarHOC);