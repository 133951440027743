import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalExam from "../../common/Modal/ModalExam";
import ModalPay from "../../hoc/modalPay";

import Sidebar from "../../hoc/sidebar";
import { general as data } from "./data";

const General = ({
  onLogout,
  token,
  currentTheme,
  onCurrentTheme,
  onGetExamQuestions,
  onGetReadModal,
  read,
  user,
}) => {
  // ------------ LOCAL STATE ----------
  const [checked, setChecked] = useState(null);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalPay, setModalPay] = useState(false);

  // ------------- SET HISTORY --------------
  const history = useHistory(null);

  // -------------- SET REF -----------------
  let refs = React.useRef([React.createRef(), React.createRef()]);

  // -------------- LOGOUT ----------------
  const logoutUser = () => {
    onLogout(token);
  };

  useEffect(() => {
    if (checked !== null) {
      refs.current[0].current.classList.remove("blob");
      refs.current[1].current.classList.remove("blob");
    }
  }, [checked]);

  // --------------------- GET QUESTIONS ---------------------
  const getQuestions = () => {
    if (checked === null) {
      refs.current[0].current.classList.add("blob");
      refs.current[1].current.classList.add("blob");
      return;
    }
    if (checked === 2) {
      onCurrentTheme(token);

      if (read) {
        onGetExamQuestions(token);
        sessionStorage.setItem(
          "general",
          JSON.stringify({ type: "exam", theme: currentTheme })
        );
        history.push("/test");
      } else {
        setModalInfo(true);
      }
    } else {
      history.push("/started_learn");
      sessionStorage.removeItem("general");
    }
  };

  const openModalPay = () => {
    setModalPay(true);
  };

  const setMyTest = () => {
    history.push("/my_test");
  };

  return (
    <div className="row">
      {modalInfo && (
        <ModalExam
          onGetReadModal={onGetReadModal}
          setModalInfo={setModalInfo}
          rules={data}
          token={token}
          onGetExamQuestions={onGetExamQuestions}
          currentTheme={currentTheme}
        />
      )}
      {modalPay && <ModalPay setModalPay={setModalPay} />}
      <Sidebar />
      <main className="content">
        <div className="content__inner">
          <div className="general">
            <form className="general__choose">
              <p className="general__choose-text">
                Виберіть режим складання тестів:
              </p>
              <div className="general__choose__list">
                {data.type.map((t, i) => {
                  return (
                    <div
                      key={t.id}
                      className="test__radio general__choose__item"
                    >
                      <input
                        className="visually-hidden test__input"
                        type="radio"
                        name={t.name}
                        id={t.id}
                        onChange={() => setChecked(t.id)}
                        checked={checked === t.id}
                      />
                      <label
                        htmlFor={t.id}
                        className="input__radio"
                        ref={refs.current[i]}
                      >
                        {t.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* {(checked === 2 || checked === 3) && (
                <div className="general__theme">
                  <label className="general__theme__label">Тема</label>
                  <select
                    className="general__theme__list"
                    onChange={(e) => {
                      onCurrentTheme(e.target.value);
                    }}
                  >
                    {themes &&
                      themes?.map((t) => {
                        return (
                          <option
                            className="general__theme__item"
                            value={t.id}
                            disabled={t.successful_theme_id === 0}
                            key={t.id}
                          >
                            {t.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )} */}
            </form>

            <div className="general__btns">
              <button
                className="general__start__btn btn"
                onClick={getQuestions}
              >
                Розпочати
              </button>

              {history.location.pathname === "/general" && (
                <button
                  className="general__start__btn btn btn--mobile"
                  onClick={setMyTest}
                >
                  Прогрес навчання
                </button>
              )}
              {user && !user?.premium_check && (
                <button
                  className="general__start__btn btn btn--mobile btn--green"
                  onClick={openModalPay}
                >
                  ПРЕМІУМ підписка
                </button>
              )}
              <button className="general__exit__btn btn" onClick={logoutUser}>
                Вийти
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default General;
