import React from "react";
import { connect } from "react-redux";
import Footer from "../components/Footer/Footer";
import Actions from "../redux/actions";

export const FooterHOC = (props) => {
    return <Footer {...props} />
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
    setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
    setUser: (data) => dispatch(Actions.auth.getUserSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterHOC)