export const cards = [
    {
        id: 1,
        iconName: 'rocket.svg',
        title: 'Тариф «преміум»',
        price: '199 грн',
        text: [
            'Онлайн тренажер для вивчення та перевірки знань з правил дорожнього руху, найновіші офіційні тестові питання, алгоритм іспиту та дизайн максимально наближені до іспиту в сервісному центрі МВС',
            'Послідовний доступ до всіх тем та тестових питань',
            'Підказки до тестових питань допоможуть вивчити необхідні пункти правил дорожнього руху',
        ],
        btnText: 'придбати преміум'
    },
    {
        id: 2,
        iconName: 'ball.svg',
        title: 'Тариф «лайт»',
        price: 'Безкоштовно',
        text: [
            'Онлайн тренажер для перевірки знань з правил дорожнього руху в режимі «іспит», найновіші офіційні тестові питання, алгоритм іспиту та дизайн максимально наближені до іспиту в сервісному центрі МВС',
            'Демонстраційний доступ до вивчення перших двох тем з правил дорожнього руху України',
        ],
        btnText: 'почати навчання'
    }
]