import QRCode from "qrcode.react";

import "./qr-code.scss";

function QrCode() {
  const link = `http://localhost:3000/#/register/premium?code=001%2007%20037`;

  return (
    <section className="main qr-code">
      <div className="container">
        <h2 className="title">
          Зареєструйтесь та готуйтесь до іспитів з правил дорожньго руху з нами!
        </h2>

        <div className="qr-code-content">
          <QRCode value={link} />
        </div>
      </div>
    </section>
  );
}

export default QrCode;
