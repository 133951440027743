import "./button.scss";

export default function Button({
  btnText,
  type,
  otherClass,
  bg,
  size,
  color,
  rounded,
  ...props
}) {
  const otherClasses = otherClass ? otherClass : "";
  const bgClass = bg ? `btn--${bg}` : "";
  const sizeClass = size ? `btn--${size}` : "";
  const roundedClass = rounded ? `btn--rounded-${rounded}` : "";
  const classes = [bgClass, sizeClass, otherClasses, roundedClass]
    .filter(Boolean)
    .join(" ");

  return (
    <button className={`btn ${classes}`} type={type || "button"} {...props}>
      {btnText}
    </button>
  );
}
