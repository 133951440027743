export const content = {
    title: 'Чому ми найкращі',
    blocks: [
        {
            id: 1,
            icon: 'preference-photo-1.svg',
            title: 'Теоретичне навчання',
            text: 'Наша програма вивчення Правил дорожнього руху України максимально повна та ефективна, а іспити наближені до реальних іспитів в ТСЦ'
        },
        {
            id: 2,
            icon: 'preference-photo-2.svg',
            title: 'Навчання у будь який час',
            text: 'Навчання онлайн дозволяє ефективно планувати Ваш час. Курс завжди доступний у вашому смартфоні у будь-якому місці при наявності інтернету'
        },
        {
            id: 3,
            icon: 'preference-photo-3.svg',
            title: 'Доступні ціни',
            text: 'У нашого онлайн-тренажера найнижча ціна на ринку і найвища ефективність, що доведено на практиці сотнями учнів і випускників автошкіл. '
        }
    ]
}