import sprites from "../../../assets/img/sprites.svg";
import { statisticsFunc } from "./constants";
import "./progress-statistics.scss";

const ProgressStatistics = ({ totalProgress }) => {
  const statistics = statisticsFunc(totalProgress);
  return (
    <div className="progress-statistics">
      {statistics.map((item, idx) => (
        <div className="progress-statistics-block" key={idx}>
          <svg
            className={`progress-statistics-block__icon ${
              item.color ? `progress-statistics-block__icon--${item.color}` : ""
            }`}
          >
            <use xlinkHref={`${sprites}#${item.icon}`}></use>
          </svg>
          <div className="progress-statistics-block__text">{item.title}</div>
          <div
            className={`progress-statistics-block__count ${
              item.color
                ? `progress-statistics-block__count--${item.color}`
                : ""
            }`}
          >
            {item.count}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressStatistics;
