import types from "../types/index";

const initialState = {
  success: false,
  errors: {},
  errorsSystem: "",
  premiumExpired: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.resetPassword.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: payload,
      };
    case types.resetPassword.RESET_PASSWORD_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case types.resetPassword.RESET_PASSWORD_ERRORS_SYSTEM:
      return {
        ...state,
        errorsSystem: payload,
      };
    case types.resetPassword.SET_PREMIUM_EXPIRED:
      return {
        ...state,
        premiumExpired: payload,
      };
    default:
      return state;
  }
};

export default reducer;
