import { images } from '../../helpers/importImg';

const RoadSign = ({ idx, formSign, status }) => {
    let white = '';

    const signIcon = () => {
        if (formSign === 'round') {
            if (status === 'error') {
                return images['round_mistake.svg'];
            }

            if (status === 'done') {
                return images['round_complite.svg'];
            }

            if (status === 'current') {
                return images['round_available.svg'];
            }
            return images['round_locked.svg'];
        }

        if (formSign === 'rectangle') {
            if (status === 'error') {
                white = 'sign__num--white';
                return images['rectangle_mistake.svg'];
            }

            if (status === 'done') {
                return images['rectangle_complite.svg'];
            }

            if (status === 'current') {
                white = 'sign__num--white';
                return images['rectangle_available.svg'];
            }

            return images['rectangle_locked.svg'];
        }

        if (formSign === 'triangular') {
            if (status === 'error') {
                return images['triangular_mistake.svg'];
            }

            if (status === 'done') {
                return images['triangular_complite.svg'];
            }

            if (status === 'current') {
                return images['triangular_available.svg'];
            }

            return images['triangular_locked.svg'];
        }

        if (formSign === 'hexagon') {
            if (status === 'error') {
                white = 'sign__num--white';
                return images['hexagon_mistake.svg']
            }

            if (status === 'done') {
                return images['hexagon_complite.svg']
            }

            if (status === 'current') {
                white = 'sign__num--white';
                return images['hexagon_available.svg']
            }

            return images['hexagon_locked.svg']
        }

        if (formSign === 'octagon') {
            if (status === 'error') {
                white = 'sign__num--white';
                return images['octagon_mistake.svg']
            }

            if (status === 'done') {
                return images['octagon_complite.svg']
            }

            if (status === 'current') {
                white = 'sign__num--white';
                return images['octagon_available.svg']
            }

            return images['octagon_locked.svg']
        }

        if (formSign === 'triangular_rotate') {
            if (status === 'error') {
                return images['triangular-rotate_mistake.svg']
            }

            if (status === 'done') {
                return images['triangular-rotate_complite.svg']
            }

            if (status === 'current') {
                return images['triangular-rotate_available.svg']
            }

            return images['triangular-rotate_locked.svg']
        }

        if (formSign === 'rhombus') {
            if (status === 'error') {
                white = 'sign__num--white';
                return images['rhombus_mistake.svg'];
            }

            if (status === 'done') {
                return images['rhombus_complite.svg'];
            }

            if (status === 'current') {
                white = 'sign__num--white';
                return images['rhombus_available.svg'];
            }

            return images['rhombus_locked.svg'];
        }

        if (formSign === 'square') {
            if (status === 'error') {
                white = 'sign__num--white';
                return images['square_mistake.svg'];
            }

            if (status === 'done') {
                return images['square_complite.svg'];
            }

            if (status === 'current') {
                white = 'sign__num--white';
                return images['square_available.svg'];
            }

            return images['square_locked.svg'];
        }
    }

    return (
        <>
            <img src={signIcon()} alt={`${idx + 1} блок питань`} />
            <p className={`sign__num ${white}`}>{idx + 1}</p>
        </>

    )
}

export default RoadSign