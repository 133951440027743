import React from "react";
import { connect } from "react-redux";
import { Main } from "./router";

export const MainHOC = (props) => {
  return <Main {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps, null)(MainHOC);
