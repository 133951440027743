export default {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",

  LOGIN_ERRORS: "LOGIN_ERRORS",
  LOGIN_SYSTEM_ERROR: "LOGIN_SYSTEM_ERROR",
};
