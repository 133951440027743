import React from "react";
import { connect } from "react-redux";
import Main from "../components/Main/Main";
import Actions from "../redux/actions/index";

export const MainHOC = (props) => {
  return <Main {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  premiumContent: state.main.premiumContent,
  errorsPremium: state.main.errorsPremium,
  feedback: state.main.feedback,
  errorsFeedback: state.main.errorsFeedback,
  freePremium: state.main.freePremium,
  freePremiumMessage: state.main.freePremiumMessage,
  price: state.general.price,
  usersAmounts: state.general.usersAmounts,
});

const mapDispatchToProps = (dispatch) => ({
  onGetFreePremium: () => dispatch(Actions.main.getFreePremiumRequest()),
  onGetFreePremiumSubscribe: (data) =>
    dispatch(Actions.main.getFreePremiumSubscribeRequest(data)),
  onGetFeedback: (data) => dispatch(Actions.main.getFeedbackRequest(data)),
  onSetFeedbackSucces: (data) =>
    dispatch(Actions.main.getFeedbackSuccess(data)),
  onSetFeedbackErrors: (data) => dispatch(Actions.main.getFeedbackErrors(data)),
  onSetFreePremiumSubscribe: (data) =>
    dispatch(Actions.main.getFreePremiumSubscribeSuccess(data)),
  onSetFreePremiumMessage: (data) =>
    dispatch(Actions.main.getFreePremiumSubscribeMessage(data)),
  onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),

  setConfig: () => dispatch(Actions.general.getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainHOC);
