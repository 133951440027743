import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions";
import Repetition from "../components/Repetition/Repetition";

export const RepetitionHOC = (props) => {
  return <Repetition {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  themes: state.repeat.themes,
  themesError: state.repeat.themesError,
  themesCount: state.repeat.themesCount,
  themesCountError: state.repeat.themesCountError,
});

const mapDispatchToProps = (dispatch) => ({
  setRepeatThemes: (data) =>
    dispatch(Actions.repeat.getRepeatThemesRequest(data)),
  setRepeatThemesCount: (data) =>
    dispatch(Actions.repeat.getRepeatThemesCountRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepetitionHOC);
