import React, { useState } from "react";
import FieldPhone from "../../common/Fields/FieldPhone/FieldPhone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Error from "../../common/Error/Error";
import Button from "../../common/Button/Button";

const RecoverForm = ({
  onRecover,
  setErrors,
  setErrorsSystem,
  errors,
  errorsSystem,
}) => {
  const history = useHistory();

  const [user, setUser] = useState({
    phone: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      phone: user.phone,
    };
    setErrors({});
    setErrorsSystem("");
    onRecover(data);
  };
  const goBack = () => {
    history.goBack();
  };

  return (
    <form className="auth__form" onSubmit={handleSubmit}>
      <div>
        <FieldPhone
          labelText="Номер телефону"
          id="phone"
          name="phone"
          type="tel"
          forLabel="phone"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              phone: e.target.value.trim(),
            }))
          }
        />
        {errors?.phone && <Error errorText={errors.phone} />}
      </div>

      {errorsSystem && <Error errorText={errorsSystem} />}

      <div className="auth__btns">
        <Button btnText="відправити" bg="black" type="submit" />
        <Button btnText="Назад" bg="yellow" onClick={goBack} />
      </div>
    </form>
  );
};

export default RecoverForm;
