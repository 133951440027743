import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { pathColor } from "../../helpers/progressPathColor";
import { images } from "../../helpers/importImg";
import "react-circular-progressbar/dist/styles.css";

const ProgressBarCar = ({ totalProgress }) => {
  return (
    <div className="progressbar-car">
      <CircularProgressbar
        value={totalProgress.progress}
        className="progressbar-car__circle"
        styles={buildStyles({
          pathColor: pathColor(totalProgress),
          trailColor: "#46494F",
        })}
      />
      <div className="progressbar-car__block">
        <img src={images["car.svg"]} className="progressbar-car__img" />
        <p className="progressbar-car__num">{totalProgress.progress}%</p>
      </div>
    </div>
  );
};

export default ProgressBarCar;
