import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchResetStatistics({ payload }) {
  const response = yield API.progress.getResetStatistics(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError(response.data.message));
    } else {
      yield put(Actions.progress.progressSuccess(true));
    }
  } else if (response.status === 500) {
    yield put(Actions.popup.messageError(response.data.message));
  } else if (response.status === 422) {
    yield put(Actions.progress.progressErrors("Помилка"));
  } else {
    yield put(Actions.popup.messageError("Номера телефону не знайдено"));
  }
}
