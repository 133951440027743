import classNames from "classnames";
import FieldPasswordBtn from "./FieldPasswordBtn";
import "../field.scss";
import "../input.scss";
import "../password.scss";

function FieldPassword({
  labelText,
  name,
  id,
  type,
  forLabel,
  fieldClass,
  inputSize,
  inputColor,
  ...props
}) {
  return (
    <div
      className={classNames("field", {
        [fieldClass]: fieldClass,
      })}
    >
      <label htmlFor={forLabel || ""} className="field__title">
        {labelText}
      </label>
      <div className="password">
        <FieldPasswordBtn />
        <input
          className={classNames("input password__input", {
            "input--sm": inputSize === "sm",
            "input--white": inputColor === "white",
          })}
          type={type || "text"}
          name={name || ""}
          id={id || ""}
          {...props}
        />
      </div>
    </div>
  );
}

export default FieldPassword;
