import types from "../types/index"

const getAboutPageData = () => ({
    type: types.about.ABOUT_PAGE_REQUEST,
})

const getAboutPageDataSuccess = (payload) => ({
    type: types.about.ABOUT_PAGE_SUCCESS,
    payload,
})

export default {
    getAboutPageData,
    getAboutPageDataSuccess,
};