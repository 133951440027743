import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchRegister({ payload }) {
  const response = yield API.register.register(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.register.registerSystemErrors(response.data.message));
    } else {
      yield put(Actions.auth.loginSuccess(response.data.access_token));
      yield put(Actions.register.registerSuccessLink(response.data.link));
      yield sessionStorage.setItem("link", response.data.link);
      yield sessionStorage.setItem("token", response.data.access_token);
    }
  } else if (response.status === 500) {
    yield put(Actions.register.registerSystemErrors("Сторінка в розробці."));
  } else if (response.status === 422) {
    yield put(Actions.register.registerErrors(response.data.errors));
  } else {
    yield put(
      Actions.register.registerSystemErrors("Не вдалось зареєструватись")
    );
  }
}

export function* watchRegisterBuyPremium({ payload }) {
  const response = yield API.register.buyPremium(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.register.registerSystemErrors(response.data.message));
    } else {
      yield put(Actions.register.registerSuccessLink(response.data.link));
      yield sessionStorage.setItem("link", response.data.link);
    }
  } else if (response.status === 500) {
    yield put(Actions.register.registerSystemErrors(response.data.message));
  } else if (response.status === 422) {
    yield put(Actions.register.registerErrors(response.data.errors));
  } else {
    yield put(
      Actions.register.registerSystemErrors("Не вдалось зареєструватись")
    );
  }
}

export function* watchGetCategories() {
  const response = yield API.register.getCategories();

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Категорій не знайдено"));
    } else {
      yield sessionStorage.setItem("categories", JSON.stringify(response.data));
      yield put(Actions.register.registerCategoriesSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Категорій не знайдено"));
  }
}

export function* watchGetSchool() {
  const response = yield API.register.getSchool();

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Автошкіл не знайдено"));
    } else {
      yield sessionStorage.setItem(
        "school",
        JSON.stringify(response.data.data.favorites)
      );
      yield put(
        Actions.register.registerSchoolSuccess(response.data.data.favorites)
      );
      yield put(
        Actions.register.registerGroupAutocomplete(
          response.data.data.autocomplete
        )
      );
    }
  } else {
    yield put(Actions.popup.messageError("Автошкіл не знайдено"));
  }
}

export function* watchGetGroup(payload) {
  const response = yield API.register.getGroup(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Груп не знайдено"));
    } else {
      yield sessionStorage.setItem("group", JSON.stringify(response.data.data));
      yield put(Actions.register.registerGroupSuccess(response.data.data));
    }
  } else {
    yield put(Actions.popup.messageError("Груп не знайдено"));
  }
}

// import { put } from "redux-saga/effects";
// import { API } from "../api/index";
// import Actions from "../redux/actions/index";

// export function* watchRegister({ payload }) {
//   const response = yield API.register.register(payload);

//   if (response.status === 200) {
//     if (response.data.status === "error") {
//       yield put(Actions.popup.messageError(response.data.message));
//     } else {
//       yield sessionStorage.setItem("token", response.data.access_token);
//       yield put(Actions.register.registerSuccess(response.data.access_token));
//     }
//   } else if (response.status === 500) {
//     yield put(Actions.popup.messageError(response.data.message));
//   } else if (response.status === 422) {
//     yield put(Actions.register.registerErrors(response.data.errors));
//   } else {
//     yield put(Actions.popup.messageError("Не вдалось зареєструватись"))
//   }
// }
