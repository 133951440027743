import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";

export default function ModalPay({ price, setPrice, setModalPay }) {
  const history = useHistory();

  useEffect(() => {
    setPrice();
    // eslint-disable-next-line
  }, []);

  const payByn = () => {
    history.push("/premium");
    document.body.classList.remove("open");
  };

  const closeModal = () => {
    setModalPay(false);
    document.body.classList.remove("open");
  };

  const types = ["/started_learn", "/learn", "/finish", "/"];

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content modal__content--pb">
          <h2 className="title title--sm">Оплатити ПРЕМІУМ доступ</h2>
          <p>
            Придбавши <b>тариф «Преміум»</b> Ви отримаєте можливість проходження
            всіх найновіших офіційно затверджених ГСЦ МВС тестових питань в
            режимі <b>«Навчання»</b> за всіма темами, включаючи усі передбачені
            вказаною при реєстрації категорією транспортних засобів додаткових
            питань, які відповідно до алгоритму іспиту обов’язково будуть
            присутні на іспиті в сервісному центрі.
          </p>
          <p>
            <b>Режим «Навчання»</b> побудовано за принципом поступового
            проходження всіх тем. Кожна тема розділена на блоки, які вміщують до
            20 питань. Після проходження блоку Ви маєте повторно пройти ті
            тестові запитання, в яких було допущено помилку. Повернутись до
            проходження блоку можна в будь-який зручний для Вас час, немає
            необхідності в проходженні цілої теми за один сеанс, адже в темі
            може бути до 358 тестових питань.
          </p>
          <p>
            Враховуючи, що більшість автошкіл навчають учнів за дещо зміненим
            порядком тем, теми <b>«Дорожні знаки»</b> та{" "}
            <b>«Дорожня розмітка»</b> доступні незалежно від проходження інших
            тем.
          </p>
          <p>
            Після успішного проходження всіх запитань за темою Вам потрібно буде
            скласти <b>«Залік»</b>, який вміщує питання з усіх пройдених тем.
            Залік створено для того, щоб поступово підготовлювати Вас до іспиту
            та освіжити в пам’яті тестові питання з пройдених тем. В заліку
            дозволено робити певну кількість помилок, більшу за допустиму
            кількість помилок в іспиті.
          </p>
          <p>
            В режимі <b>«Навчання»</b> до кожного тестового питання є можливість
            переглянути <b>«Підказку»</b>, яка включає короткий витяг з Правил
            дорожнього руху України (іншого Закону України або Постанови КМУ в
            залежності від теми) або коментаря викладача з теоретичної
            підготовки автошколи. Завдяки підказкам Ви зрозумієте чому саме
            запропонована відповідь є правильною.
          </p>
          <div className="modal__bottom">
            <p className="modal-text">
              Вартість: <span className="modal-text__price">{price}грн</span>
            </p>
            <div className="modal__btns">
              <Button
                bg="yellow"
                btnText="Оплатити"
                size="sm"
                rounded="md"
                onClick={payByn}
              />
              <Button
                btnText="Закрити"
                bg="red"
                size="sm"
                rounded="md"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
