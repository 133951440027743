import auth from "./auth";
import register from "./register";
import general from "./general";
import test from "./test";
import result from "./result";
import myTests from "./myTests";
import popup from "./popup";
import agreement from "./agreement";
import map from "./map";
import recover from "./recover";
import resetPassword from "./resetPassword";
// import registerPremium from "./registerPremium";
import main from "./main";
import about from "./about";
import progress from "./progress";
import repeat from "./repeat";

export default {
  auth,
  register,
  general,
  test,
  result,
  myTests,
  popup,
  agreement,
  map,
  recover,
  resetPassword,
  // registerPremium,
  main,
  about,
  progress,
  repeat,
};
