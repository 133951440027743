import types from "../types/index";

const recoverRequest = (payload) => ({
  type: types.recover.RECOVER_REQUEST,
  payload,
});

const recoverSuccess = (payload) => ({
  type: types.recover.RECOVER_SUCCESS,
  payload,
});

const recoverErrors = (payload) => ({
  type: types.recover.RECOVER_ERRORS,
  payload,
});

const recoverErrorsSystem = (payload) => ({
  type: types.recover.RECOVER_ERRORS_SYSTEM,
  payload,
});

export default {
  recoverRequest,
  recoverSuccess,
  recoverErrors,
  recoverErrorsSystem,
};
