import { NavLink } from "react-router-dom";

import { images } from "../../../../helpers/importImg";
import { linkUrl } from "../../../../helpers/linkUrl";

import { content } from "./data";

function Banner({ token }) {
  return (
    <div className="banner">
      <div className="container banner__container">
        <div className="banner__content">
          <h1 className="banner__title title">{content.title}</h1>
          <p className="banner__text">{content.text}</p>
          <NavLink
            to={`/${linkUrl(token)}`}
            className="banner__btn banner__btn--desktop btn btn--yellow btn--md"
          >
            {content.btnText}
          </NavLink>
        </div>

        <picture className="banner__photo">
          <source
            srcSet={images["banner-photo-1.png"]}
            media="(min-width: 768px)"
            alt="Photo"
            width="1"
            height="1"
            decoding="async"
          />
          <img
            src={images["banner-photo-2.png"]}
            alt="Photo"
            width="1"
            height="1"
            decoding="async"
          />
        </picture>

        <NavLink
          to={`/${linkUrl(token)}`}
          className="banner__btn banner__btn--mobile btn btn--yellow btn--md"
        >
          {content.btnText}
        </NavLink>
      </div>
    </div>
  );
}

export default Banner;
