import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchRepeatThemes({ payload }) {
  const response = yield API.repeat.repeatThemes(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.repeat.getRepeatThemesError(true));
    } else {
      if (response.data) {
        yield put(Actions.repeat.getRepeatThemesSuccess(response.data));
      }
    }
  } else {
    yield put(Actions.repeat.getRepeatThemesError(true));
  }
}

export function* watchRepeatThemesCount({ payload }) {
  const response = yield API.repeat.repeatThemesCount(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.repeat.getRepeatThemesCountError(true));
    } else {
      if (response.data) {
        yield put(Actions.repeat.getRepeatThemesCountSuccess(response.data));
      }
    }
  } else {
    yield put(Actions.repeat.getRepeatThemesCountError(true));
  }
}
