import types from "../types/index";

const initialState = {
  themes: [],
  currentTheme: 0,
  subscribe: false,
  price: 0,
  usersAmounts: 0,
  read: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.general.GET_THEMES_SUCCESS:
      return {
        ...state,
        themes: payload,
      };
    case types.general.CURRENT_THEME:
      return {
        ...state,
        currentTheme: payload,
      };
    case types.general.GET_PREMIUM_SUBSCRIBE_SUCCES:
      return {
        ...state,
        subscribe: payload,
      };
    case types.general.GET_PREMIUM_SUBSCRIBE_REMOVE:
      return {
        ...state,
        subscribe: payload,
      };
    case types.general.GET_PREMIUM_PRICE_SUCCESS:
      return {
        ...state,
        price: payload,
      };
    case types.general.GET_READ_MODAL_INFO_EXAM:
      return {
        ...state,
        read: payload,
      };
    case types.general.GET_USERS_AMOUNT_SUCCESS:
      return {
        ...state,
        usersAmounts: payload,
      };
    default:
      return state;
  }
};

export default reducer;
