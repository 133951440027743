import { images } from "../../helpers/importImg";
import { colorCount, statistics } from "./constants";
import "./repetition-statistics.scss";

function RepetitionStatistics({ themesCount, activeIndex, toggleThemes }) {
  return (
    <div className="repetition-statistics">
      {statistics.map((item, index) => {
        return (
          <div
            className={`repetition-statistics__block ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => toggleThemes(index, item.type)}
            key={item.title}
          >
            <img
              src={images[item.img]}
              alt={item.title}
              title={item.title}
              className="repetition-statistics__img"
            />
            <div>
              <div
                className={`repetition-statistics__count ${colorCount(
                  item.type
                )}`}
              >
                {themesCount[item.type]}
              </div>
              <div className="repetition-statistics__title">{item.title}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RepetitionStatistics;
