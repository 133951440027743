import React from "react";
import { connect } from "react-redux";
import ModalBad from "../common/Modal/ModalBad";
import Actions from "../redux/actions/index";

export const ModalBadHOC = (props) => {
    return <ModalBad {...props} />;
};

const mapStateToProps = (state) => ({
    currentTheme: state.general.currentTheme,

});

const mapDispatchToProps = (dispatch) => ({
    onCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
    onGetRetestQuestion: (data) => dispatch(Actions.test.getRetestQuestionsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBadHOC);