import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchRecover({ payload }) {
  const response = yield API.recover.recover(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.recover.recoverErrorsSystem(response.data.message));
    } else {
      yield sessionStorage.setItem("tokenRecover", response.data.token);
      yield put(Actions.recover.recoverSuccess(response.data.token));
    }
  } else if (response.status === 500) {
    yield put(Actions.recover.recoverErrorsSystem("Сторінка в розробці."));
  } else if (response.status === 422) {
    yield put(Actions.recover.recoverErrors(response.data.errors));
  } else {
    yield put(
      Actions.recover.recoverErrorsSystem("Номера телефону не знайдено")
    );
  }
}
