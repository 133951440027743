import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions";
import Theory from "../components/Theory/Theory";

export const TheoryHOC = (props) => {
  return <Theory {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TheoryHOC);
