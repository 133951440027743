import types from "../types/index";

const getQuestionsRequest = (payload) => ({
  type: types.test.GET_QUESTIONS_REQUEST,
  payload,
});

const getQuestionsSuccess = (payload) => ({
  type: types.test.GET_QUESTIONS_SUCCESS,
  payload,
});

const getAllQuestionsRequest = (payload) => ({
  type: types.test.GET_ALL_QUESTIONS_REQUEST,
  payload,
});

const getExamQuestionsRequest = (payload) => ({
  type: types.test.GET_EXAM_QUESTIONS_REQUEST,
  payload,
});

const getRetestQuestionsRequest = (payload) => ({
  type: types.test.GET_RETEST_QUESTIONS_REQUEST,
  payload,
});


const getQuestionsFromSessionRequest = (payload) => ({
  type: types.test.GET_QUESTIONS_FROM_SESSION_REQUEST,
  payload,
});

const getCryptRequest = (payload) => ({
  type: types.test.GET_CRYPT_REQUEST,
  payload,
});

const getCryptSuccess = (payload) => ({
  type: types.test.GET_CRYPT_SUCCESS,
  payload,
});

const getBlockQuestionsRequest = (payload) => ({
  type: types.test.GET_BLOCK_QUESTIONS_REQUEST,
  payload,
});

const getBlockQuestionsSuccess = (payload) => ({
  type: types.test.GET_QUESTIONS_SUCCESS,
  payload,
});

const getEmptyData = (payload) => ({
  type: types.test.GET_EMPTY_DATA,
  payload,
});

const setSendQuestionSuccess = () => ({
  type: types.test.SET_SEND_QUESTIONS_SUCCESS
});

export default {
  getRetestQuestionsRequest,
  getQuestionsRequest,
  getQuestionsSuccess,
  getAllQuestionsRequest,
  getExamQuestionsRequest,
  getQuestionsFromSessionRequest,
  getCryptRequest,
  getCryptSuccess,
  getBlockQuestionsRequest,
  getBlockQuestionsSuccess,
  getEmptyData,
  setSendQuestionSuccess,
};
