import { NavLink } from "react-router-dom/cjs/react-router-dom";

import { images } from "../../helpers/importImg";

import "./dropdown.scss";

export const DropdownList = ({ items, logoutUser }) => {
  return (
    <ul className="dropdown-list">
      {items &&
        items.map((item, idx) => {
          const RenderComponent = item.link ? NavLink : "button";
          return (
            <li key={idx}>
              <RenderComponent
                to={item.link}
                className="dropdown-list__item"
                onClick={() => !item.link && logoutUser()}
              >
                <span className="dropdown-list__icon">
                  <img
                    src={images[item.icon]}
                    alt={item.title}
                    title={item.title}
                    width="1"
                    height="1"
                  />
                </span>
                {item.title}
              </RenderComponent>
            </li>
          );
        })}
    </ul>
  );
};
