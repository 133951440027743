import React from "react";
import { connect } from "react-redux";
import Recover from "../components/ResetPassword/Recover";
import Actions from "../redux/actions/index";

export const RecoverHOC = (props) => {
  return <Recover {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.recover.token,
  errors: state.recover.errors,
  errorsSystem: state.recover.errorsSystem,
});

const mapDispatchToProps = (dispatch) => ({
  onRecover: (data) => dispatch(Actions.recover.recoverRequest(data)),
  setRecover: (data) => dispatch(Actions.recover.recoverSuccess(data)),
  setErrors: (data) => dispatch(Actions.recover.recoverErrors(data)),
  setErrorsSystem: (data) =>
    dispatch(Actions.recover.recoverErrorsSystem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverHOC);
