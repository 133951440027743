import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom";
import FieldPassword from "../../common/Fields/FieldPassword/FieldPassword";
import FieldPhone from "../../common/Fields/FieldPhone/FieldPhone";
import Button from "../../common/Button/Button";
import Error from "../../common/Error/Error";

const AuthForm = ({
  onLogin,
  setErrors,
  errors,
  systemError,
  setSystemErrors,
}) => {
  const history = useHistory();

  const [user, setUser] = useState({
    phone: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(user);
    setErrors({});
    setSystemErrors("");
  };

  const setRegisterPage = () => {
    history.push("/register");
    setErrors({});
    setSystemErrors("");
  };

  return (
    <form className="auth__form" onSubmit={handleSubmit}>
      <div>
        <FieldPhone
          labelText="Номер телефону"
          id="phone"
          name="phone"
          type="tel"
          forLabel="phone"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              phone: e.target.value.trim(),
            }))
          }
        />
        {errors?.phone && <Error errorText={errors.phone} />}
      </div>

      <div>
        <FieldPassword
          labelText="Пароль"
          id="password"
          name="password"
          type="password"
          forLabel="password"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              password: e.target.value.trim(),
            }))
          }
        />
        {errors?.password && <Error errorText={errors.password} />}
      </div>

      {systemError && <Error errorText={systemError} />}

      <NavLink
        to="/recover"
        className="auth__link link link--underline link--md"
      >
        Забули пароль?
      </NavLink>

      <div className="auth__btns">
        <Button btnText="Увійти" bg="black" type="submit" />
        <Button
          btnText="Зареєструватись"
          bg="yellow"
          onClick={setRegisterPage}
        />
      </div>

      <NavLink
        to="/agreement"
        className="auth__link link link--underline link--lg"
      >
        Політика конфіденційності?
      </NavLink>
    </form>
  );
};

export default AuthForm;
