import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetFreePremium({ payload }) {
  const response = yield API.main.freePremiumBlock(payload);

  if (response.status === 200 || response.status === 201) {
    if (!response.data.status) {
      yield put(Actions.main.getFreePremiumErrors(response.data.errors));
    } else {
      yield put(Actions.main.getFreePremiumSuccess(response.data));
    }
  } else {
    yield put(Actions.main.getFreePremiumErrors(response.data.errors));
  }
}

export function* watchGetFeedback({ payload }) {
  const response = yield API.main.feedback(payload);

  if (response.status === 200 || response.status === 201) {
    if (!response.data.status) {
      yield put(Actions.main.getFeedbackErrors(response.data.errors));
    } else {
      yield put(Actions.main.getFeedbackSuccess(response.data.message));
    }
  } else {
    yield put(Actions.main.getFeedbackErrors(response.data.errors));
  }
}

export function* watchGetFreePremiumSubscribe({ payload }) {
  const response = yield API.main.freePremium(payload);

  if (response.status >= 200 && response.status <= 400) {
    if (!response.data.status) {
      yield put(Actions.main.getFreePremiumSubscribeMessage(response.data.message));
    } else {
      yield put(Actions.main.getFreePremiumSubscribeSuccess(response.data.message));
    }
  } else {
    yield put(Actions.main.getFreePremiumSubscribeErrors(response.data.errors));
  }
}