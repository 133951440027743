import React, { useEffect, useRef } from "react";
import css from "./Popup.module.sass";

const Popup = (props) => {
  // ---------------- GET PROPS ----------------
  const { error, success, messageError, messageSuccess } = props;
  //   ----------------- SET REFS ----------------------
  const err = useRef(null);
  const succ = useRef(null);
  // ------------------- SHOW MESSAGE ----------------------
  const showMessage = (element, onSet) => {
    let top = 0;

    let timer = setInterval(() => {
      if (top === 0 && window.innerWidth > 991) {
        let top = 38;

        clearInterval(timer);
        setTimeout(() => {
          timer = setInterval(() => {
            if (top === -38) {
              clearInterval(timer);
              onSet(null);
            }
            element.current.style.top = `${top}px`;
            top -= 2;
          }, 10);
        }, 2000);
      } else {
        let top = 160;

        clearInterval(timer);
        setTimeout(() => {
          timer = setInterval(() => {
            if (top === -160) {
              clearInterval(timer);
              onSet(null);
            }
            element.current.style.top = `${top}px`;
            top -= 2;
          }, 40);
        }, 100);
      }
      element.current.style.top = `${top}px`;
      top += 2;
    }, 10);
  };

  // ---------------------- KEEP TRACK MESSAGE -----------------------
  useEffect(() => {
    if (error !== null) {
      showMessage(err, messageError);
    }
    if (success !== null) {
      showMessage(succ, messageSuccess);
    }
  }, [error, success]);

  return (
    <>
      <div className={css.error} ref={err}>
        {error}
      </div>
      <div className={css.success} ref={succ}>
        {success}
      </div>
    </>
  );
};

export default Popup;
