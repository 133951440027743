import { apiFunctions } from "./apiFunctions";

export const register = {
  // register: (data) => apiFunctions.postFunc(data, '/register/start', null),
  // registerPremium: (data) => apiFunctions.postFunc(data, '/register/premium', null),
  register: (data) => apiFunctions.postFunc(data, "/register", null),
  buyPremium: (data) =>
    apiFunctions.postFunc(data.data, "/register/finish", data.token),
  getCategories: () => apiFunctions.getFunc(null, "/categories", null),
  getSchool: () => apiFunctions.getFunc(null, "/school", null),
  getGroup: (data) => apiFunctions.postFunc(data, "/group", null),
};
