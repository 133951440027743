import types from "../types/index";

const registerRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST,
  payload,
});

const registerBuyRequest = (payload) => ({
  type: types.register.REGISTER_BUY_REQUEST,
  payload,
});

const registerSuccessLink = (payload) => ({
  type: types.register.REGISTER_SUCCESS_LINK,
  payload,
});

const registerErrors = (payload) => ({
  type: types.register.REGISTER_ERRORS,
  payload,
});

const registerSystemErrors = (payload) => ({
  type: types.register.REGISTER_SYSTEM_ERRORS,
  payload,
});

const registerCategoriesSuccess = (payload) => ({
  type: types.register.REGISTER_CATEGORIES_SUCCESS,
  payload,
});

const registerCategoriesRequest = (payload) => ({
  type: types.register.REGISTER_CATEGORIES_REQUEST,
  payload,
});

const registerSchoolSuccess = (payload) => ({
  type: types.register.REGISTER_SCHOOL_SUCCESS,
  payload,
});

const registerSchoolRequest = (payload) => ({
  type: types.register.REGISTER_CATEGORIES_REQUEST,
  payload,
});

const registerGroupAutocomplete = (payload) => ({
  type: types.register.SCHOOLS_AUTOCOMPLETE_SUCCESS,
  payload,
});

const registerGroupSuccess = (payload) => ({
  type: types.register.RREGISTER_GROUP_SUCCESS,
  payload,
});

const registerGroupRequest = (payload) => ({
  type: types.register.REGISTER_GROUP_REQUEST,
  payload,
});

export default {
  registerBuyRequest,
  registerRequest,
  registerSuccessLink,
  registerErrors,
  registerSystemErrors,
  registerCategoriesRequest,
  registerCategoriesSuccess,
  registerSchoolRequest,
  registerSchoolSuccess,
  registerGroupAutocomplete,
  registerGroupSuccess,
  registerGroupRequest,
};
