import './Progressbar.scss';

const Progressbar = ({ otherClass, colorProcces, proccesNum }) => {

    const colorProccesClass = colorProcces ? `progressbar--${colorProcces}` : '';
    const otherClasses = otherClass ? otherClass : '';
    const classes = [otherClasses, colorProccesClass].filter(Boolean).join(' ');
    const progress = proccesNum;
    return (
        <div className={`progressbar ${classes}`}>
            <div className="progressbar-line">
                <div className="progressbar-line__progress" style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    )
}

export default Progressbar;