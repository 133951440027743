import { colors } from "../../helpers/colors";
import { images } from "../../helpers/importImg";

// ---------------- SET COLOR FOR PROGRESSBAR CAR FOR SELECTED THEME ------------------------
export const pathColor = (theme) => {
  if (theme) {
    if (theme.progress_car?.error === 0 && theme.progress_car?.progress < 50) {
      return colors.green;
    }

    if (theme.progress_car?.progress >= 50) {
      return colors.gold;
    }

    if (theme.progress_car?.error > 0) {
      return colors.red;
    }

    return colors.gray;
  }
};

export const starExamIcon = (theme, blocks, blocksDone) => {
  if (theme) {
    if (theme?.progress_car?.progress >= 50) {
      return images["star-completed.svg"];
    }

    if (
      blocksDone?.length === blocks?.length ||
      theme?.progress_car?.error > 0
    ) {
      return images["star-available.svg"];
    }

    return images["star-locked.svg"];
  }
};

// ---------------- SET COLOR FOR PROGRESSBAR LINE ------------------------
export const colorProgressbarLine = (theme) => {
  if (theme) {
    if (theme.disabled || theme.blocked) {
      return "locked";
    }

    if (!theme.disabled && theme.error > 0) {
      return "low";
    }

    if (!theme.disabled && theme.error === 0 && theme.progress === 100) {
      return "done";
    }

    if (!theme.disabled || (!theme.disable && theme.progress < 100)) {
      return "hight";
    }

    return "locked";
  }
};

// ---------------- SET CLASS FOR DIFFERNT CARDS ------------------------
export const colorClassCards = (theme, nextThemeId, currentTheme) => {
  if (theme) {
    if (theme.blocked) {
      return "card--locked";
    }

    if (theme.disabled) {
      return "card--gray";
    }

    if (theme.error > 0) {
      return "card--red";
    }

    if (theme.error === 0 && theme.progress === 100) {
      return "card--gold";
    }

    if (theme.id == currentTheme) {
      return "card--green";
    }

    if (nextThemeId === theme.id) {
      return "card--green";
    }

    if (theme.id === 33 || theme.id === 34 || theme.id === 37) {
      return "card--green";
    }

    return "card--green";
  }
};
