import Slider from "react-slick";

import PreferenceBlock from "./PreferenceBlock";

import { content } from "./data";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";;

function Preference() {

	const settings= {
        speed: 500,
        slidesToShow: 3,
		infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
			{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

	return (
		<div className='preference'>
			<div className="container">
				<h2 className="preference__title title title-md">{content.title}</h2>

				<div className="preference__slider">
					<Slider {...settings}>
						{content.blocks.map(block => <PreferenceBlock key={block.id} block={block} />)} 
					</Slider> 
				</div>
			</div>
		</div>
	)
}

export default Preference;