import types from "../types/index";

const getBlocksRequest = (payload) => ({
    type: types.map.GET_BLOCKS_REQUEST,
    payload,
});

const getBlocksSuccess = (payload) => ({
    type: types.map.GET_BLOCKS_SUCCESS,
    payload,
});

const getTotalProgressRequest = (payload) => ({
    type: types.map.GET_TOTAL_PROGRESS_REQUEST,
    payload,
});

const getTotalProgressSuccess = (payload) => ({
    type: types.map.GET_TOTAL_PROGRESS_SUCCESS,
    payload,
});

const getCurrentBlock = (payload) => ({
    type: types.map.GET_CURRENT_BLOCK,
    payload,
});

export default {
    getBlocksSuccess,
    getBlocksRequest,
    getTotalProgressSuccess,
    getTotalProgressRequest,
    getCurrentBlock,
};