import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import "./checkbox.scss";

export default function Checkbox({
  labelText,
  name,
  linkText,
  link,
  ...props
}) {
  return (
    <label className="checkbox">
      <input type="checkbox" name={name} {...props} />
      <span className="checkbox__text">
        {labelText}
        {linkText && (
          <NavLink to={link} className="link link--underline">
            {linkText}
          </NavLink>
        )}
      </span>
    </label>
  );
}
