import sprites from "../../../../assets/img/sprites.svg";

const FieldPasswordBtn = () => {
  const switchPassword = (e) => {
    const btn = e.target.closest("button");
    const input = btn.nextElementSibling;

    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }

    btn.classList.toggle("show");
  };
  return (
    <button className="password__btn" type="button" onClick={switchPassword}>
      <svg className="password__icon password__icon--close">
        <use xlinkHref={`${sprites}#eye`}></use>
      </svg>
      <svg className="password__icon password__icon--open">
        <use xlinkHref={`${sprites}#eye-off`}></use>
      </svg>
    </button>
  );
};

export default FieldPasswordBtn;
