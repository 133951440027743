export const days = (time) => {
    return Math.floor(time / (1000 * 60 * 60 * 24));
}

export const hours = (time) => {
    return Math.floor(time / (1000 * 60 * 60));
}

export const minutes = (time) => {
    return Math.floor((time / 1000 / 60) % 60);
}

export const seconds = (time) => {
    return Math.floor((time / 1000) % 60);
}

export const date = (days, minutes, seconds) => {
    const dayStr = (days) => {
        let str = '';

        str = 'днів';

        if(days === 1) {
            str = 'день';
        }

        if(days === 2 || days === 3 || days === 4) {
            str = 'дні';
        }

        return str;
    }
    return `${days}${dayStr(days)} ${minutes}хв. ${seconds}сек.`;
}