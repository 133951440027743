import React from "react";

import { HeaderProfileDropdown } from "./HeaderProfileDropdown";

const HeaderProfile = ({ user, logoutUser }) => {
  return (
    <>
      {Object.keys(user).length > 0 ? (
        <HeaderProfileDropdown user={user} logoutUser={logoutUser} />
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderProfile;
