import React from "react";
import { connect } from "react-redux";
import Agreement from "../components/Agreement/Agreement";
import Actions from "../redux/actions/index";

export const AgreementHOC = (props) => {
  return <Agreement {...props}/>
};

const mapStateToProps = (state) => ({
  term: state.agreement.term,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setTerm: () => dispatch(Actions.agreement.getTermPageData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementHOC);