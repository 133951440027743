import classNames from "classnames";
import "../field.scss";
import "../input.scss";

function Field({
  labelText,
  name,
  id,
  forLabel,
  fieldClass,
  inputSize,
  inputColor,
  inputClasses,
  type,
  isDisabled,
  ...props
}) {
  return (
    <div
      className={classNames("field", {
        [fieldClass]: fieldClass,
      })}
    >
      <label htmlFor={forLabel || ""} className="field__title">
        {labelText}
      </label>
      <input
        {...props}
        className={classNames("input", {
          "input--sm": inputSize === "sm",
          "input--white": inputColor === "white",
          [inputClasses]: inputClasses,
        })}
        type={type || "text"}
        name={name || ""}
        id={id || ""}
        disabled={isDisabled ? true : false}
        {...props}
      />
    </div>
  );
}

export default Field;
