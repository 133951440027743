import { useEffect, useState } from "react";
import ProgressAction from "./ProgressAction";
import ProgressGeneral from "./ProgressGeneral";
import ProgressStatistics from "./ProgressStatistics";
import ResetModal from "./ResetModal";

import "./progress.scss";

const Progress = ({
  token,
  totalProgress,
  setResetStatistics,
  resetSucces,
  onGetTotalProgress,
  setResetStatisticsSusccess,
}) => {
  const [openModalReset, setOpenModalReset] = useState(false);

  useEffect(() => {
    if (resetSucces && token) {
      setOpenModalReset(false);
      onGetTotalProgress(token);
      setResetStatisticsSusccess(false);
      document.body.classList.remove("open");
    }
  }, [resetSucces]);

  return (
    <>
      {openModalReset && (
        <ResetModal
          token={token}
          setOpenModalReset={setOpenModalReset}
          setResetStatistics={setResetStatistics}
        />
      )}
      <div className="progress">
        <ProgressGeneral totalProgress={totalProgress} />
        <ProgressStatistics totalProgress={totalProgress} />
        <ProgressAction
          text="Повторити слабкі теми"
          iconColor="green"
          icon="arrow-right-lg"
          action="redirect"
        />
        {/* <div className="progress__block"></div>
        <div className="progress__block"> */}
        {/* <ProgressAction
            text="Скинути статистику до 0"
            iconColor="red"
            icon="refresh"
            action="reset"
            setOpenModalReset={setOpenModalReset}
          /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Progress;
