import types from "../types/index";

const resetPasswordRequest = (payload) => ({
  type: types.resetPassword.RESET_PASSWORD_REQUEST,
  payload,
});

const resetPasswordSuccess = (payload) => ({
  type: types.resetPassword.RESET_PASSWORD_SUCCESS,
  payload,
});

const resetPasswordErrors = (payload) => ({
  type: types.resetPassword.RESET_PASSWORD_ERRORS,
  payload,
});

const resetPasswordErrorsSystem = (payload) => ({
  type: types.resetPassword.RESET_PASSWORD_ERRORS_SYSTEM,
  payload,
});

const setPremiumExpired = (payload) => ({
  type: types.resetPassword.SET_PREMIUM_EXPIRED,
  payload,
});

export default {
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordErrors,
  resetPasswordErrorsSystem,
  setPremiumExpired,
};
