import { images } from "../../helpers/importImg";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../common/Button/Button";
import ProgressBarCar from "../ProgressBarCar/ProgressBarCar";

const HeaderMobile = ({ token, user, totalProgress, openModalPay }) => {
  return (
    <div className="header__mobile nav-mobile">
      {token ? (
        <div className="nav-mobile__top">
          <div className="nav-mobile__avatar">
            <img src={images["user.svg"]} alt="user icon" />
          </div>

          <div className="nav-mobile__block">
            <div className="nav-mobile__name">
              {user?.first_name} {user?.last_name}
            </div>
            <div className="nav-mobile__row">
              <div className="nav-mobile__position">
                {user?.role_id === 4 ? "Викладач" : "Учень"}
              </div>
              {user?.premium_check && (
                <div className="nav-mobile__status">Преміум</div>
              )}
            </div>
          </div>

          <div className="nav-mobile__progressbar">
            {totalProgress && <ProgressBarCar totalProgress={totalProgress} />}
          </div>
        </div>
      ) : (
        ""
      )}

      <ul className="nav-mobile__list">
        <li className="nav-mobile__item">
          <div className="nav-mobile__icon">
            <img src={images["home.svg"]} alt="home icon" />
          </div>
          <NavLink to="/" className="nav-mobile__link">
            Головна
          </NavLink>
        </li>
        <li className="nav-mobile__item">
          <div className="nav-mobile__icon">
            <img src={images["car-black.svg"]} alt="car black icon" />
          </div>
          <NavLink to="/started_learn" className="nav-mobile__link">
            Тести
          </NavLink>
        </li>
        {/* {token && user.premium_check && (
          <>
            <li className="nav-mobile__item">
              <span className="nav-mobile__icon">
                <img src={images["car-black.svg"]} alt="car black icon" />
              </span>
              <NavLink to="/theory" className="nav-mobile__link">
                Теорія
              </NavLink>
            </li>
            <li className="nav-mobile__item">
              <span className="nav-mobile__icon">
                <img src={images["car-black.svg"]} alt="car black icon" />
              </span>
              <NavLink to="/repetition" className="nav-mobile__link">
                Повторення
              </NavLink>
            </li>
          </>
        )} */}
        <li className="nav-mobile__item">
          <div className="nav-mobile__icon">
            <img src={images["avatar.svg"]} alt="avatar icon" />
          </div>
          <NavLink to="/about" className="nav-mobile__link">
            Про нас
          </NavLink>
        </li>
        {!token && (
          <li className="nav-mobile__item">
            <div className="nav-mobile__icon">
              <img src={images["dashboard.svg"]} alt="sign in icon" />
            </div>
            <NavLink to="/login" className="nav-mobile__link">
              Увійти/Реєстрація
            </NavLink>
          </li>
        )}
      </ul>

      {Object.keys(user).length > 0 && !user?.premium_check ? (
        <div className="nav-mobile__bottom">
          <p className="nav-mobile__text">
            Придбай преміум та навчайся швидше!
          </p>
          <Button
            btnText="Преміум"
            otherClass="nav-mobile__btn"
            bg="yellow"
            onClick={openModalPay}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HeaderMobile;
