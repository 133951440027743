import types from "../types/index";

const initialState = {
  about: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.about.ABOUT_PAGE_SUCCESS:
      return {
        ...state, about: payload
      }
    default:
      return state;
  }
};

export default reducer;