import { NavLink } from "react-router-dom";

export default function ModalInfoPremiun({ setModalInfoPremium, content }) {
  const closeModal = () => {
    setModalInfoPremium(false);
    document.body.classList.remove("open");
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <h2 className="title title--sm">
            Увійдіть під своїм логіном, щоб спробувати ПРЕМІУМ
          </h2>

          <p className="modal-text modal-text--thin modal-text--center">
            Для того щоб спробувати всі переваги тарифу ПРЕМІУМ безкоштовно
            впродовж {content.premium_days} днів, спочатку пройдіть реєстрацію
            та увійдіть під своїм логіном
          </p>

          <div className="modal__btns">
            <NavLink
              to="/register"
              className="btn btn--red btn--sm btn--rounded-md"
              onClick={closeModal}
            >
              Реєстрація
            </NavLink>
            <NavLink
              to="/login"
              className="btn btn--yellow btn--sm btn--rounded-md"
              onClick={closeModal}
            >
              Увійти
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
