import React from "react";
import { connect } from "react-redux";
import Result from "../components/Result/Result";
import Actions from "../redux/actions/index";

export const ResultHOC = (props) => {
  return <Result {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.popup.error,
  success: state.popup.success,
  failedTheme: state.test.failedTheme,
});

const mapDispatchToProps = (dispatch) => ({
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  // ------------------- RESET RESULTS -------------------------
  onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
  onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),

  sendResult: (data) => dispatch(Actions.result.sendResultRequest(data)),
  sendBadResult: (data) => dispatch(Actions.result.sendBadResultRequest(data)),

  // ----------------------- GET QUESTIONS -------------------------------
  onGetExamQuestions: (data) => dispatch(Actions.test.getExamQuestionsRequest(data)),

  // ---------------- THEME --------------------
  onGetThemes: (data) => dispatch(Actions.general.getThemesRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultHOC);
