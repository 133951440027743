import React from "react";
import { connect } from "react-redux";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import Actions from "../redux/actions/index";

export const ResetPasswordHOC = (props) => {
  return <ResetPassword {...props} />;
};

const mapStateToProps = (state) => ({
  success: state.resetPassword.success,
  errors: state.resetPassword.errors,
  errorsSystem: state.resetPassword.errorsSystem,
  premiumExpired: state.resetPassword.premiumExpired,
});

const mapDispatchToProps = (dispatch) => ({
  onResetPassword: (data) =>
    dispatch(Actions.resetPassword.resetPasswordRequest(data)),
  setResetPassword: (data) =>
    dispatch(Actions.resetPassword.resetPasswordSuccess(data)),
  setRecover: (data) => dispatch(Actions.recover.recoverSuccess(data)),
  setErrors: (data) => dispatch(Actions.recover.recoverErrors(data)),
  setErrorsSystem: (data) =>
    dispatch(Actions.recover.recoverErrorsSystem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordHOC);
