import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import Register from "../components/Auth/Register";

export const RegisterHOC = (props) => {
  return <Register {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  link: state.register.link,
  errors: state.register.errors,
  errorSystem: state.register.errorSystem,

  categories: state.register.categories,
  price: state.general.price,
});

const mapDispatchToProps = (dispatch) => ({
  onRegister: (data) => dispatch(Actions.register.registerRequest(data)),
  setErrors: (data) => dispatch(Actions.register.registerErrors(data)),
  setErrorsSystem: (data) =>
    dispatch(Actions.register.registerSystemErrors(data)),

  setCategories: () => dispatch(Actions.register.registerCategoriesRequest()),
  setConfig: () => dispatch(Actions.general.getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterHOC);
