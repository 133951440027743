import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions";
import ProfileEdit from "../components/Profile/ProfileEdit/ProfileEdit";

export const ProfileEditHOC = (props) => {
  return <ProfileEdit {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditHOC);
