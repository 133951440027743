import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions";
import Profile from "../components/Profile/Profile";

export const ProfileHOC = (props) => {
  return <Profile {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHOC);
