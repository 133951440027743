import { NavLink } from "react-router-dom";
import Button from "../../common/Button/Button";

const ResultContent = ({
  title,
  correctCount,
  answers,
  general,
  resetTest,
  setExamQuestion,
  logoutUser,
  failed,
  btnAgain,
  subtitle,
}) => {
  return (
    <>
      <h1 className="result__title title title--sm">{title}</h1>
      {subtitle && (
        <span className="result__subtitle">
          В тарифі «Преміум» доступний перегляд тем в яких допущено помилки. Щоб
          перейти на «Преміум»
          <NavLink
            to="/premium"
            className="link link--inline link--blue btn--sm btn--rounded-md"
            onClick={resetTest}
          >
            натисніть тут
          </NavLink>
        </span>
      )}
      <div
        className={`result__item result__item--${
          failed ? "danger" : "success"
        }`}
      >
        {" "}
        Ваш бал:{" "}
        <span>
          {correctCount || 0}/{answers?.length}
        </span>
      </div>
      <div className="result__btns">
        {general?.type === "exam" && (
          <NavLink to="/test" className="btn btn--blue btn--sm btn--rounded-md">
            Правильні відповіді
          </NavLink>
        )}
        <NavLink
          to="/started_learn"
          onClick={resetTest}
          className="btn btn--blue btn--sm btn--rounded-md"
        >
          Повернутись на головну
        </NavLink>
        {btnAgain && (
          <Button
            bg="blue"
            size="sm"
            rounded="md"
            btnText="Пройти ще раз іспит"
            onClick={setExamQuestion}
          />
        )}
      </div>
    </>
  );
};

export default ResultContent;
