import types from "../types/index";

const getFreePremiumRequest = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_REQUEST,
    payload
});

const getFreePremiumSuccess = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_SUCCESS,
    payload,
});

const getFreePremiumErrors = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_ERRORS,
    payload,
});

const getFreePremiumSubscribeRequest = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_SUBSCRIBE_REQUEST,
    payload
});

const getFreePremiumSubscribeSuccess = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_SUBSCRIBE_SUCCESS,
    payload,
});

const getFreePremiumSubscribeErrors = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_SUBSCRIBE_ERRORS,
    payload,
});

const getFreePremiumSubscribeMessage = (payload) => ({
    type: types.main.GET_FREE_PREMIUM_SUBSCRIBE_MESSAGE,
    payload,
});

const getFeedbackRequest = (payload) => ({
    type: types.main.GET_FEEDBACK_REQUEST,
    payload
});

const getFeedbackSuccess = (payload) => ({
    type: types.main.GET_FEEDBACK_SUCCES,
    payload,
});

const getFeedbackErrors = (payload) => ({
    type: types.main.GET_FEEDBACK_ERRORS,
    payload,
});

export default {
    getFreePremiumRequest,
    getFreePremiumSuccess,
    getFreePremiumErrors,
    getFeedbackRequest,
    getFeedbackSuccess,
    getFeedbackErrors,
    getFreePremiumSubscribeRequest,
    getFreePremiumSubscribeSuccess,
    getFreePremiumSubscribeErrors,
    getFreePremiumSubscribeMessage,
};