import { useEffect, useRef } from "react";

import "./Alert.scss";

export default function Alert({ alertText, resetText }) {
  const alertRef = useRef(false);

  useEffect(() => {
    if (alertText) {
      let time = 4;

      const timer = setInterval(() => {
        if (time < 0) {
          clearInterval(timer);
          alertRef.current.classList.remove("show");
          if (resetText) {
            resetText(null);
          }
        } else {
          alertRef.current.classList.add("show");
        }

        --time;
      }, 1000);
    }
  }, [alertText]);

  return (
    <div className="alert" ref={alertRef}>
      {alertText}
    </div>
  );
}
