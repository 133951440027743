export const profileLinks = [
  {
    link: "/profile",
    title: "Мій профіль",
    icon: "avatar.svg",
  },
  {
    link: "",
    title: "Вийти",
    icon: "exit-1.svg",
  },
];
