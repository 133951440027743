import { colors } from "./colors";

// ---------------- SET COLOR PATH FOR PROGRESSBAR CAR ------------------------
export const pathColor = (totalProgress) => {
  if (totalProgress) {
    if (totalProgress.error === 0 && totalProgress.progress === 0) {
      return colors.green;
    }

    if (totalProgress.error === 0 && totalProgress.progress === 100) {
      return colors.gold;
    }

    if (totalProgress.error > 0) {
      return colors.red;
    }

    return colors.gray;
  }
};
