import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../common/Button/Button";
import Sidebar from "../../hoc/sidebar";
import "./Agreement.scss";

const Agreement = ({ setTerm, token }) => {
  const history = useHistory();

  useEffect(() => {
    setTerm();

    if (!token) {
      history.push("/agreement");
    }
  }, []);

  const term = JSON.parse(sessionStorage.getItem("term"));

  const setPage = () => {
    history.goBack();
  };

  return (
    <div className="row">
      <Sidebar />
      <main className="content">
        <div className="content__inner jc-center">
          <div className="agreement page-block">
            <h1 className="agreement__title title">{term?.data.title}</h1>
            <div
              className="agreement__desc"
              dangerouslySetInnerHTML={{ __html: term?.data.content }}
            />
            <div className="agreement__btn">
              <Button btnText="Назад" bg="red" size="lg" onClick={setPage} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Agreement;
