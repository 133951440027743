import types from "../types/index";

const initialState = {
  errors: {},
  errorSystem: "",
  token: "",
  link: "",
  categories: null,
  schools: null,
  autocomplete: null,
  groups: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.register.REGISTER_SUCCESS_LINK:
      return {
        ...state,
        link: payload,
      };
    case types.register.REGISTER_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case types.register.REGISTER_SYSTEM_ERRORS:
      return {
        ...state,
        errorSystem: payload,
      };
    case types.register.REGISTER_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
      };
    case types.register.REGISTER_SCHOOL_SUCCESS:
      return {
        ...state,
        schools: payload,
      };
    case types.register.RREGISTER_GROUP_SUCCESS:
      return {
        ...state,
        groups: payload,
      };
    case types.register.SCHOOLS_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autocomplete: payload,
      };
    default:
      return state;
  }
};

export default reducer;
