import types from "../types/index";

const loginRequest = (payload) => ({
  type: types.auth.LOGIN_REQUEST,
  payload,
});

const loginSuccess = (payload) => ({
  type: types.auth.LOGIN_SUCCESS,
  payload,
});

const logoutRequest = (payload) => ({
  type: types.auth.LOGOUT_REQUEST,
  payload,
});

const getUserRequest = (payload) => ({
  type: types.auth.GET_USER_REQUEST,
  payload,
});

const getUserSuccess = (payload) => ({
  type: types.auth.GET_USER_SUCCESS,
  payload,
});

const getLoginErrors = (payload) => ({
  type: types.auth.LOGIN_ERRORS,
  payload,
});
const getLoginSystemErrors = (payload) => ({
  type: types.auth.LOGIN_SYSTEM_ERROR,
  payload,
});

export default {
  loginRequest,
  loginSuccess,
  logoutRequest,
  getUserRequest,
  getUserSuccess,
  getLoginErrors,
  getLoginSystemErrors,
};
