import StarExam from "../../hoc/starExam";
import RoadBlock from '../../hoc/roadBlock';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { pathColor } from "./helpers";
import { images } from "../../helpers/importImg";

import 'react-circular-progressbar/dist/styles.css';
import { useRef } from "react";

export default function Map({
    blocks,
    selectedTheme,
    blocksDone,
    setAllowOpenModal,
}) {
    const containerRef = useRef(null);
    // ---------------- SET COLOR FOR PROGRESSBAR CAR FOR SELECTED THEME ------------------------
    const styleProgressbarCar = {
        pathColor: pathColor(selectedTheme),
        trailColor: '#46494F'
    }

    const mapShortClass = blocks?.length < 9 ? 'map--short' : '';
    const mapDesktopClasses = [mapShortClass].filter(Boolean).join(' ');

    return (
        <div className={`map ${mapDesktopClasses}`}>
            {(blocks?.length > 9 || (window.innerWidth < 991 && blocks?.length > 5)) &&
                <img src={images['sign-next.svg']} className="map__next" alt="Next icon" width='1' height='1' />}
            <div className="map__content" ref={containerRef}>
                <StarExam
                    selectedTheme={selectedTheme}
                    blocksDone={blocksDone}
                    setAllowOpenModal={setAllowOpenModal}
                    starClass='map__star'
                    starCursorClass='map__star--cursor'
                    position='map__star--right'
                />
                <div className="map__progressbar">
                    <div className="progressbar-car">
                        <CircularProgressbar
                            value={selectedTheme?.progress_car?.progress}
                            className="progressbar-car__circle"
                            styles={buildStyles(styleProgressbarCar)}
                        />
                        <div className="progressbar-car__block">
                            <img src={images['car.svg']} className="progressbar-car__img" />
                            <p className="progressbar-car__num">{selectedTheme?.progress_car?.progress}%</p>
                        </div>
                    </div>
                </div>
                <img src={images['road.svg']} className="map__road" alt="Road picture" width="1" height="1" />
                {blocks?.map((block, idx) => {
                    return (
                        <div key={idx}>
                            <RoadBlock
                                block={block}
                                selectedTheme={selectedTheme}
                                setAllowOpenModal={setAllowOpenModal}
                                idx={idx}
                                mapClass='map__sign'
                                containerRef={containerRef}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
