import ProgressBarCar from "../ProgressBarCar/ProgressBarCar";
import "./progress-general.scss";

const ProgressGeneral = ({ totalProgress }) => {
  return (
    <div className="progress-general">
      <div className="progress-general__title">Загальний прогрес:</div>
      <ProgressBarCar totalProgress={totalProgress} />
      {totalProgress.theme ? (
        <div className="progress-general__label">
          {totalProgress.theme} тема
        </div>
      ) : (
        ""
      )}

      {totalProgress.theme ? (
        <div className="progress-general__text">
          Вітаємо, ви вже на {totalProgress.progress}% ближче до своєї мети!
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProgressGeneral;
