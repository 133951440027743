import classNames from "classnames";
import "./title.scss";
import "./subtitle.scss";
import "./title-section.scss";

const Title = ({
  title,
  titlesize,
  titleClasses,
  titleTag,
  subtitle,
  subtitleColor,
  position,
  price,
  text,
}) => {
  const Component = titleTag ? titleTag : "div";
  return (
    <div
      className={classNames("title-section", {
        "title-section--center": position === "center",
      })}
    >
      <Component
        className={classNames("title", {
          "title--sm": titlesize === "sm",
          [titleClasses]: titleClasses,
        })}
      >
        {title}
      </Component>
      {subtitle && (
        <p
          className={classNames("subtitle", {
            "subtitle--green": subtitleColor === "green",
          })}
        >
          {subtitle}
          {price && <span className="price">{price} грн</span>}
        </p>
      )}
      {text && <p>{text}</p>}
    </div>
  );
};

export default Title;
