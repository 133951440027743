import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetBlocks({ payload }) {
    const response = yield API.map.getBlocks(payload);

    if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "error") {
            yield put(Actions.popup.messageError("Не вдалося підгрузити блоки"));
        } else {
            yield put(Actions.map.getBlocksSuccess(response.data));
        }
    } else {
        yield put(Actions.popup.messageError("Не вдалося підгрузити блоки"));
    }
}

export function* watchGetTotalProgress({ payload }) {
    const response = yield API.map.getTotalProgress(payload);

    if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "error") {
            yield put(Actions.popup.messageError("Не вдалося підгрузити прогресбар"));
        } else {
            yield put(Actions.map.getTotalProgressSuccess(response.data));
        }
    } else {
        yield put(Actions.popup.messageError("Не вдалося підгрузити прогресбар"));
    }
}