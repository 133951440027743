import { useHistory } from "react-router-dom";

import Button from "../../common/Button/Button";

import { images } from "../../helpers/importImg";

function PlugPage({ buttonText, goBack }) {
  const history = useHistory();

  const setPage = () => {
    if (goBack) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  return (
    <div className="plug-page">
      <div className="container plug-page__container">
        <img
          src={images["plug-photo-1.png"]}
          className="plug-page__img"
          alt="plug"
          width="1"
          height="1"
          decoding="async"
        />
        <p className="plug-page__text">Сторінка в розробці</p>
        <Button
          btnText={buttonText ? buttonText : "на головну"}
          bg="transparent"
          size="md"
          otherClass="plug-page__btn"
          onClick={setPage}
        />
      </div>
    </div>
  );
}

export default PlugPage;
