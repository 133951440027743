import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../hoc/header";
import Footer from "../../hoc/footer";
import Title from "../../common/Title/Title";
import ModalResetPassword from "../../common/Modal/ModalResetPassword";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPassword({
  onResetPassword,
  setResetPassword,
  success,
  errors,
  errorsSystem,
  setErrors,
  premiumExpired,
  setErrorsSystem,
}) {
  const history = useHistory();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (success && !premiumExpired) {
      sessionStorage.removeItem("tokenRecover");
      setResetPassword(false);
      history.replace("/login");
      setErrors({});
      setErrorsSystem("");
    }
  }, [success]);

  useEffect(() => {
    if (premiumExpired) {
      setModal(true);
      document.body.classList.add("open");
    }
  }, [premiumExpired]);

  return (
    <div className="main">
      {modal && <ModalResetPassword setModal={setModal} />}
      <Header />

      <main className="main__container">
        <div className="auth container">
          <div className="auth__container">
            <Title
              title="Онлайн тренажер для вивчення і перевірки знань з Правил Дорожнього руху України"
              titlesize="sm"
              titleClasses="auth__title"
              titleTag="h1"
              subtitle="Відновлення пароля"
              subtitleColor="green"
              position="center"
              text="На Ваш номер телефону надіслано SMS повідомлення з кодом для відновлення доступу, введіть цей код та створіть новий пароль доступу."
            />
            <ResetPasswordForm
              setResetPassword={setResetPassword}
              onResetPassword={onResetPassword}
              setErrors={setErrors}
              errors={errors}
              errorsSystem={errorsSystem}
              setErrorsSystem={setErrorsSystem}
            />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
