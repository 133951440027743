import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import "./Modal.scss";
import { setCorrectWord } from "./helpers";

export default function ModalResults({
  setModalBad,
  onGetRetestQuestion,
  onCurrentTheme,
  currentTheme,
}) {
  const history = useHistory();

  const token = sessionStorage.getItem("token");
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  const id = localStorage.getItem("currentThemeId");
  const idx = sessionStorage.getItem("currentBlockIdx");

  const correctCount = answers?.filter(
    (answer) => answer.correct === 1
  )?.length;

  // ---------------- GET CURRENT THEME ------------------------
  useEffect(() => {
    if (id) {
      onCurrentTheme(id || 1);
    }
    // eslint-disable-next-line
  }, []);

  const resetStorage = () => {
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };

  // ------------- RESET TEST -------------
  const resetTest = () => {
    setModalBad(false);
    history.push("/learn");
  };

  // ------------- SET RETEST -------------
  const setRetest = () => {
    resetStorage();
    setModalBad(false);
    onGetRetestQuestion({ id: Number(currentTheme), token: token, idx: idx });
    sessionStorage.setItem(
      "general",
      JSON.stringify({
        type: "retest",
        theme: Number(currentTheme),
        block: idx,
      })
    );
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={resetTest}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <p className="modal-text modal-text--center modal__bad">
            Ви відповіли правильно на {correctCount || 0}{" "}
            {setCorrectWord(correctCount)} з {answers?.length}
          </p>
          <p className="modal-text modal-text--center">
            Спробуйте ще раз уважно відповісти на питання в яких надано
            неправильну відповідь.
          </p>
          <div className="modal__btns">
            <Button
              btnText="На мапу"
              bg="red"
              size="sm"
              rounded="md"
              otherClass="modal__thin"
              onClick={resetTest}
            />
            <Button
              btnText="Пройти ще раз!"
              bg="yellow"
              size="sm"
              rounded="md"
              otherClass="modal__bold"
              onClick={setRetest}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
