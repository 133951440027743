import types from "../types/index";

const initialState = {
  questions: [],
  crypt: null,
  success: false,
  empty: false,
  failedTheme: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.test.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: payload,
      };
    case types.test.GET_CRYPT_SUCCESS:
      return {
        ...state,
        crypt: payload,
      };
    case types.test.GET_EMPTY_DATA:
      return {
        ...state,
        empty: payload
      }
    case types.test.SET_SEND_QUESTIONS_SUCCESS: 
      return {
        ...state,
        success: true
      }
    case types.result.SET_FAILED_THEME: 
      return {
        ...state,
        failedTheme: payload
      }
    default:
      return state;
  }
};

export default reducer;
