export const statistics = [
  {
    img: "red-lights.svg",
    type: "low",
    title: "Слабкі теми",
    color: "red",
  },
  {
    img: "yellow-lights.svg",
    type: "medium",
    title: "Середні теми",
    color: "",
  },
  {
    img: "green-lights.svg",
    type: "strong",
    title: "Сильні теми",
    color: "green",
  },
];

export const colorCount = (type) => {
  switch (type) {
    case "low":
      return "repetition-statistics__count--red";
    case "strong":
      return "repetition-statistics__count--green";
    default:
      return "";
  }
};

export const degreeIcon = (type) => {
  switch (type) {
    case "low":
      return "degree-red.svg";
    case "medium":
      return "degree-yellow.svg";
    case "strong":
      return "degree-green.svg";
    default:
      return "";
  }
};

export const degreeColor = (type) => {
  switch (type) {
    case "low":
      return "repetition-topic__degree--red";
    case "strong":
      return "repetition-topic__degree--green";
    default:
      return "";
  }
};

export const degreeText = (type) => {
  switch (type) {
    case "low":
      return "Слабка";
    case "medium":
      return "Середня";
    case "strong":
      return "Сильна";
  }
};
