import types from "../types/index";

const initialState = {
    premiumContent: {},
    errorsPremium: {},
    feedback: null,
    errorsFeedback: {},
    freePremium: null,
    errorsFreePremium: {},
    freePremiumMessage: '',
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.main.GET_FREE_PREMIUM_SUCCESS:
            return {
                ...state,
                premiumContent: payload,
            };
        case types.main.GET_FREE_PREMIUM_ERRORS:
            return {
                ...state,
                errorsPremium: payload,
            };

        case types.main.GET_FEEDBACK_SUCCES:
            return {
                ...state,
                feedback: payload,
            };
        case types.main.GET_FEEDBACK_ERRORS:
            return {
                ...state,
                errorsFeedback: payload,
            };

        case types.main.GET_FREE_PREMIUM_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                freePremium: payload,
            };
        case types.main.GET_FREE_PREMIUM_SUBSCRIBE_ERRORS:
            return {
                ...state,
                errorsFreePremim: payload,
            };
        case types.main.GET_FREE_PREMIUM_SUBSCRIBE_MESSAGE:
            return {
                ...state,
                freePremiumMessage: payload,
            };
        default:
            return state;
    }
};

export default reducer;