import React, { useEffect, useState } from 'react';
import Header from '../../hoc/header';
import Footer from '../../hoc/footer';

import './About.scss';

function About({ about, setAbout }) {

	useEffect(() => {
		setAbout();
	}, []);

  return (
    <div className="main">
			<Header />

			<main className='about'>
				<div className="about__container container">
					<h2 className="about__title">{about?.data.title}</h2>

					<div className="about__content" dangerouslySetInnerHTML={{ __html: about?.data.content }}></div>
				</div>
			</main>

			<div className="main__footer">
				<Footer />
			</div>
    </div>
  )
}

export default About