import { images } from "../../helpers/importImg";
import { NavLink } from "react-router-dom";

import "./Plug.scss";

function Plug({ plugText }) {
  return (
    <div className="plug">
      <img
        src={images["plug-photo-2.png"]}
        className="plug__img"
        alt="Plug"
        width="1"
        height="1"
      />
      {plugText}
      <NavLink to="/login" className="plug__btn btn btn--yellow btn--md">
        ВХІД/РЕЄСТРАЦІЯ
      </NavLink>
    </div>
  );
}

export default Plug;
