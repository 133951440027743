import React from "react";
import { connect } from "react-redux";
import Progress from "../components/Progress/Progress";
import Actions from "../redux/actions";

export const progressHOC = (props) => {
  return <Progress {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  totalProgress: state.map.totalProgress,
  resetSucces: state.progress.resetSucces,
  resetError: state.progress.resetError,
});

const mapDispatchToProps = (dispatch) => ({
  setResetStatistics: (data) =>
    dispatch(Actions.progress.progressRequest(data)),
  setResetStatisticsSusccess: (data) =>
    dispatch(Actions.progress.progressSuccess(data)),
  onGetTotalProgress: (data) =>
    dispatch(Actions.map.getTotalProgressRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(progressHOC);
