import types from "../types/index";

const initialState = {
  term: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.agreement.AGREEMENT_TERM_SUCCESS:
      return {
        ...state, term: payload
      }
    default:
      return state;
  }
};

export default reducer;