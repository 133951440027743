import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import About from "../components/About/About";

export const AboutHOC = (props) => {
  return <About {...props}/>
};

const mapStateToProps = (state) => ({
  about: state.about.about,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setAbout: () => dispatch(Actions.about.getAboutPageData()),
  setAboutSuccess: () => dispatch(Actions.about.getAboutPageDataSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutHOC);