import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Field from "../../common/Fields/Field/Field";
import Error from "../../common/Error/Error";
import FieldPassword from "../../common/Fields/FieldPassword/FieldPassword";
import Button from "../../common/Button/Button";

const ResetPasswordForm = ({
  onResetPassword,
  setResetPassword,
  setErrors,
  errors,
  errorsSystem,
  setErrorsSystem,
}) => {
  const history = useHistory();
  const [user, setUser] = useState({
    code: "",
    password: "",
    password_confirmation: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("tokenRecover");
    const data = {
      code: user.code,
      password: user.password,
      password_confirmation: user.password_confirmation,
    };
    setErrors({});
    setErrorsSystem("");
    onResetPassword({ data, token });
  };

  const setPage = () => {
    sessionStorage.removeItem("tokenRecover");
    setResetPassword(false);
    history.replace("/login");
    setErrors({});
    setErrorsSystem("");
  };

  return (
    <form className="auth__form" onSubmit={handleSubmit}>
      <div>
        <Field
          labelText="Код"
          id="code"
          name="code"
          type="text"
          forLabel="code"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              code: e.target.value.trim(),
            }))
          }
        />
        {errors?.code && <Error errorText={errors.code} />}
      </div>

      <div>
        <FieldPassword
          labelText="Пароль"
          id="password"
          name="password"
          type="password"
          forLabel="password"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              password: e.target.value.trim(),
            }))
          }
        />
        {errors?.password && <Error errorText={errors.password} />}
      </div>

      <div>
        <FieldPassword
          labelText="Повторіть пароль"
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          forLabel="password_confirmation"
          inputSize="sm"
          inputColor="white"
          onChange={(e) =>
            setUser((prevUser) => ({
              ...prevUser,
              password_confirmation: e.target.value.trim(),
            }))
          }
        />
        {errors?.password_confirmation && (
          <Error errorText={errors.password_confirmation} />
        )}
      </div>

      {errorsSystem && <Error errorText={errorsSystem} />}

      <div className="auth__btns auth__btns--row">
        <Button btnText="зберігти" bg="black" type="submit" />
        <Button btnText="Назад" bg="yellow" onClick={setPage} />
      </div>
    </form>
  );
};

export default ResetPasswordForm;
