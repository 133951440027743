import { NavLink } from "react-router-dom";

import Button from "../Button/Button";

export default function ModalInfo({ setModalInfo }) {
  const closeModal = () => {
    setModalInfo(false);
    document.body.classList.remove("open");
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <h2 className="title title--sm">Оплатити ПРЕМІУМ доступ</h2>

          <p className="modal-text modal-text--thin modal-text--center">
            Потрібно ввійти, щоб оформити ПРЕМІУМ
          </p>

          <div className="modal__btns">
            <NavLink
              to="/login"
              className="btn btn--yellow btn--sm btn--rounded-md"
              onClick={closeModal}
            >
              Вхід
            </NavLink>
            <Button
              btnText="Закрити"
              bg="red"
              size="sm"
              rounded="md"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
