import "../field.scss";
import "../textarea.scss";

function Textarea({ labelText, name, id, forLabel, otherClass, ...props }) {
  const otherClasses = otherClass ? otherClass : "";
  const classes = [otherClasses].filter(Boolean).join(" ");

  return (
    <div className={`field ${classes}`}>
      <label htmlFor={forLabel || ""} className="field__title">
        {labelText}
      </label>
      <div className="textarea input">
        <textarea
          {...props}
          type="text"
          name={name || ""}
          id={id || ""}
        ></textarea>
      </div>
    </div>
  );
}

export default Textarea;
