import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Button from "../Button/Button";

import { setCorrectWord } from "./helpers";

import "./Modal.scss";

export default function ModalResults({
  setModalResults,
  setAllowOpenModal,
  correctCount,
  failedTheme,
  user,
  currentTheme,
  token,
  onGetQuestions,
}) {
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  const countResult = JSON.parse(sessionStorage.getItem("result"));
  const general = JSON.parse(sessionStorage.getItem("general"));

  // ------------- RESET TEST -------------
  const resetTest = () => {
    setModalResults(false);
    setAllowOpenModal(true);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    document.body.classList.remove("open");
  };

  const resultExam = (countResult?.count * 50) / 100;
  const succesExam = correctCount >= resultExam;

  // const errorExamsThemes = themes?.filter(theme => theme.progress_car.error > 0);

  const setExam = () => {
    if (currentTheme && token) {
      resetTest();
      onGetQuestions({ id: currentTheme, token: token });
      sessionStorage.setItem(
        "general",
        JSON.stringify({ type: "all_theme", theme: currentTheme })
      );
      setAllowOpenModal(false);
      document.body.classList.remove("open");
    }
  };

  const setPremiumLink = () => {
    if (!user.premium_check) {
      return (
        <NavLink
          to="/premium"
          className="link link--inline link--blue"
          onClick={resetTest}
        >
          натисніть тут
        </NavLink>
      );
    }
  };

  const setBtns = () => {
    if (user.premium_check) {
      if (correctCount === answers?.length) {
        return (
          <div className="modal__btns">
            <Button btnText="Закрити" bg="yellow" onClick={resetTest} />
          </div>
        );
      } else {
        return (
          <div className="modal__btns modal__btns--col">
            <Button
              btnText="Пройти ще раз залік"
              bg="yellow"
              onClick={setExam}
            />
            <Button
              btnText="Закрити"
              bg="red"
              size="sm"
              rounded="md"
              onClick={resetTest}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="modal__btns">
          <Button
            btnText="Закрити"
            bg="yellow"
            size="sm"
            rounded="md"
            onClick={resetTest}
          />
        </div>
      );
    }
  };

  const renderResults = (general, correctCount, answers, succesExam) => {
    if (general?.type === "all_theme") {
      if (succesExam) {
        return (
          <>
            <p className="modal-text modal-text--center modal__success">
              Залік складено!
            </p>
            <p className="modal-text modal-text--center">
              Ви успішно відповіли на {correctCount || 0}{" "}
              {setCorrectWord(correctCount)} з {answers?.length}!
              <br />
              <span>
                {!user.premium_check &&
                  `В тарифі «Преміум» доступний перегляд тем в яких допущено помилки. Щоб перейти на «Преміум»`}
              </span>
              {setPremiumLink()}
            </p>

            {user?.premium_check && failedTheme.length > 0 && (
              <div className="modal__errors errors-block">
                <div className="errors-block-title">
                  Ви допустили помилки в темах:
                </div>
                <ul>
                  {failedTheme.map((theme) => (
                    <li key={theme.id}>
                      Тема {theme.id}. <span>{theme.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        );
      } else {
        return (
          <>
            <p className="modal-text modal-text--center">
              Ваша оцінка{" "}
              <span className="modal__bad">
                {" "}
                {correctCount || 0}/{answers?.length}
              </span>
            </p>
            <p className="modal-text modal-text--center">
              Залік не складено!
              <br />
              <span>
                {user.premium_check
                  ? "Рекомендуємо пройти ще раз теми в яких Ви допускаєте помилки!"
                  : `В тарифі «Преміум» доступний перегляд тем в яких допущено помилки. Щоб перейти на «Преміум»`}
              </span>
              {setPremiumLink()}
            </p>

            {user?.premium_check && failedTheme.length > 0 && (
              <div className="modal__errors errors-block">
                <div className="errors-block-title">А саме:</div>
                <ul>
                  {failedTheme.map((theme) => (
                    <li key={theme.id}>
                      Тема {theme.id}. <span>{theme.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        );
      }
    }

    if (general?.type === "retest" || general?.type === "theme") {
      if (correctCount === answers?.length) {
        return (
          <>
            <p className="modal-text modal-text--center modal__success">
              Так тримати!
            </p>
            <p className="modal-text modal-text--center">
              Ви успішно відповіли на {correctCount || 0}{" "}
              {setCorrectWord(correctCount)} з {answers?.length}!
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className="modal-text modal-text--center modal__bad">
              Ви відповіли правильно на {correctCount || 0}{" "}
              {setCorrectWord(correctCount)} з {answers?.length}
            </p>
            <p className="modal-text modal-text--center">
              Спробуйте ще раз уважно відповісти на питання в яких надано
              неправильну відповідь.
            </p>
          </>
        );
      }
    }
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={resetTest}></div>
      <div className="container modal__container">
        <div className="modal__content">
          {renderResults(general, correctCount, answers, succesExam)}
          {setBtns()}
        </div>
      </div>
    </div>
  );
}
