import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetAbout() {
  const response = yield API.about.getAbout();

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Сторінки не знайдено"));
    } else {
      yield sessionStorage.setItem("about", JSON.stringify(response.data));
      yield put(Actions.about.getAboutPageDataSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Сторінки не знайдено"));
  }
}