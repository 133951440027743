import "./Modal.scss";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom";

export default function ModalResetPassword({ setModal }) {
  const histoty = useHistory("");

  const closeModal = () => {
    setModal(false);
    document.body.classList.remove("open");
    sessionStorage.removeItem("tokenRecover");
    histoty.push("/login");
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={closeModal}></div>
      <div className="container modal__container">
        <div className="modal__content">
          <p className="modal-text modal-text--thin modal-text--center">
            Дякуємо за використання нашого сервісу! Термін дії тарифу{" "}
            <span className="modal-text__bold">«Преміум»</span> становить{" "}
            <span className="modal-text__bold">3 місяці</span>, щоб і надалі
            використовувати всі переваги{" "}
            <span className="modal-text__bold">«Преміуму»</span> можна ще раз
            активувати тариф
          </p>
          <div className="modal__btns">
            <Button
              bg="yellow"
              onClick={closeModal}
              size="sm"
              rounded="md"
              btnText="Вхід"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
