export const setCorrectWord = (count) => {
    const word_1 = 'питання';
    const word_2 = 'питань';
    switch (count) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 21:
        case 24:
            return word_1;
        default:
            return word_2;
    }
}