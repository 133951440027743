import types from "../types/index";

const initialState = {
  token: "",
  errors: {},
  errorsSystem: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.recover.RECOVER_SUCCESS:
      return {
        ...state,
        token: payload,
      };
    case types.recover.RECOVER_ERRORS:
      return {
        ...state,
        errors: payload,
      };
    case types.recover.RECOVER_ERRORS_SYSTEM:
      return {
        ...state,
        errorsSystem: payload,
      };
    default:
      return state;
  }
};

export default reducer;
