import CardTariff from "./CardTariff";

import { cards } from "./data";

function Tariffs({ user, token, price }) {
  return (
    <>
      <div className="tariffs">
        <div className="container">
          <h2 className="tariffs__title title title-md">Тарифи</h2>

          <div className="tariffs__cards">
            {cards.map((card, idx) => (
              <CardTariff
                key={idx}
                price={price}
                card={card}
                user={user}
                token={token}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tariffs;
