import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import RoadBlock from "../components/Learn/RoadBlock";

export const RoadBlockHOC = (props) => {
    return <RoadBlock  {...props} />
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    themes: state.general.themes,
    currentTheme: state.general.currentTheme,
    questions: state.test.questions,
    blocks: state.map.blocks,
    currentBlock: state.map.currentBlock,
    empty: state.test.empty,
    token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
    setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
    onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
    // ---------------- THEME --------------------
    onGetThemes: (data) => dispatch(Actions.general.getThemesRequest(data)),
    onCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
    // ---------------- BLOCKS --------------------
    onGetBlocks: (data) => dispatch(Actions.map.getBlocksRequest(data)),
    onCurrentBlock: (data) => dispatch(Actions.map.getCurrentBlock(data)),
    // ----------------------- GET QUESTIONS -------------------------------
    onGetQuestions: (data) => dispatch(Actions.test.getQuestionsRequest(data)),
    onGetExamQuestions: (data) => dispatch(Actions.test.getExamQuestionsRequest(data)),
    onGetRetestQuestion: (data) => dispatch(Actions.test.getRetestQuestionsRequest(data)),
    onGetBlockQuestions: (data) => dispatch(Actions.test.getBlockQuestionsRequest(data)),

    messageError: (data) => dispatch(Actions.popup.messageError(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(RoadBlockHOC);