import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import StartedLearn from "../components/StartedLearn/StartedLearn";

export const StartedLearnHOC = (props) => {
  return <StartedLearn {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  themes: state.general.themes,
  user: state.auth.user,
  crypt: state.test.crypt,
  subscribe: state.general.subscribe,
  price: state.general.price,
  questions: state.test.questions,
  totalProgress: state.map.totalProgress,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
  // ---------------- THEME --------------------
  onCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  // ----------------------- GET QUESTIONS -------------------------------
  onGetExamQuestions: (data) =>
    dispatch(Actions.test.getExamQuestionsRequest(data)),
  onGetCrypt: (data) => dispatch(Actions.test.getCryptRequest(data)),

  setSubscribe: (data) => dispatch(Actions.general.getSubscribe(data)),
  removeSubscribe: (data) => dispatch(Actions.general.getSubscribeRemove(data)),
  setPrice: () => dispatch(Actions.general.getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartedLearnHOC);
