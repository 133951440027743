import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import StarExam from "../components/Learn/StarExam";

export const StarExamHOC = (props) => {
    return <StarExam {...props} />;
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    themes: state.general.themes,
    currentTheme: state.general.currentTheme,
    // questions: state.test.questions,
    blocks: state.map.blocks,
    currentBlock: state.map.currentBlock,
    // empty: state.test.empty,
    token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
    // ----------------------- GET QUESTIONS -------------------------------
    onGetQuestions: (data) => dispatch(Actions.test.getQuestionsRequest(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(StarExamHOC);