import React from "react";
import { connect } from "react-redux";
import Test from "../components/Test/Test";
import Actions from "../redux/actions/index";

export const TestHOC = (props) => {
  return <Test {...props} />;
};

const mapStateToProps = (state) => ({
  questions: state.test.questions,
  allQuestions: state.test.allQuestions,
  examQuestions: state.test.examQuestions,
  retestQuestions: state.test.retestQuestions,
  token: state.auth.token,
  user: state.auth.user,
  crypt: state.test.crypt,
  empty: state.test.empty,
  token: state.auth.token,
  success: state.test.success,
});

const mapDispatchToProps = (dispatch) => ({
  sendResult: (data) => dispatch(Actions.result.sendResultRequest(data)),
  sendBadResult: (data) => dispatch(Actions.result.sendBadResultRequest(data)),
  getQuestionsFromSession: (data) => dispatch(Actions.test.getQuestionsFromSessionRequest(data)),
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
  // ------------------- RESET RESULTS -------------------------
  onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
  onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),
  onGetCrypt: (data) => dispatch(Actions.test.getCryptRequest(data)),

  messageError: (data) => dispatch(Actions.popup.messageError(data)),
  sendSuccess: (data) => dispatch(Actions.test.setSendQuestionSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestHOC);
