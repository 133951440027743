import types from "../types/index";

const initialState = {
  resetSucces: false,
  resetError: "",
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.progress.PROGRESS_SUCCESS:
      return {
        ...state,
        resetSucces: payload,
      };
    case types.progress.PROGRESS_ERRORS:
      return {
        ...state,
        resetError: payload,
      };
    default:
      return state;
  }
};

export default reducer;
